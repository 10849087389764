<template>
<icon-base :width="size + 'px'" :fill="color">
    <g>
        <g>
            <path class="st0" d="M201.12,12.57c0-4.1-3.32-7.42-7.41-7.42H56.29c-4.09,0-7.41,3.32-7.41,7.42v31.47h152.24L201.12,12.57    L201.12,12.57z"/>
            <path class="st0" d="M193.71,130.37H56.29c-4.09,0-7.41,3.32-7.41,7.42v99.65c0,4.1,3.32,7.41,7.41,7.41H193.7    c4.09,0,7.41-3.31,7.41-7.41v-99.65C201.12,133.69,197.8,130.37,193.71,130.37z M174.75,220.27h-99.5c-2.88,0-5.21-2.33-5.21-5.21    s2.33-5.21,5.21-5.21h99.51c2.88,0,5.21,2.33,5.21,5.21C179.96,217.94,177.63,220.27,174.75,220.27z M174.75,192.82h-99.5    c-2.88,0-5.21-2.34-5.21-5.21s2.33-5.21,5.21-5.21h99.51c2.88,0,5.21,2.34,5.21,5.21S177.63,192.82,174.75,192.82z M174.75,165.37    h-99.5c-2.88,0-5.21-2.34-5.21-5.21c0-2.88,2.33-5.21,5.21-5.21h99.51c2.88,0,5.21,2.34,5.21,5.21    C179.96,163.04,177.63,165.37,174.75,165.37z"/>
            <path class="st0" d="M242.09,51.25H7.91c-4.09,0-7.41,3.32-7.41,7.41v100.52c0,4.09,3.32,7.41,7.41,7.41h20.23v-15.26    c0-15.53,12.62-28.16,28.15-28.16H193.7c15.53,0,28.15,12.64,28.15,28.16v15.26h20.23c4.09,0,7.41-3.32,7.41-7.41V58.66    C249.5,54.57,246.18,51.25,242.09,51.25z M214.9,89.7h-3.93h-23.63c-3.52,0-6.64-1.76-8.51-4.45c-1.17-1.68-1.86-3.72-1.86-5.93    c0-1.59,0.36-3.1,1-4.44c1.66-3.51,5.24-5.93,9.37-5.93h13.63h10.45h3.47c2.55,0,4.88,0.92,6.69,2.45    c2.25,1.9,3.68,4.74,3.68,7.92C225.27,85.05,220.63,89.7,214.9,89.7z"/>
        </g>
    </g>
</icon-base>
</template>

<script>
export default {
name: 'iconCart',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
