<template>
    <main-modal @closeModal="$emit('closeModalMyCart')" :overflowVisible="true" title="cart.my_carts_title">
        <template v-if="filteringCarts.length || loading">
            <p class="title-14 mb-0 weight-500 mb-4 mt-3"> 
                <!-- <strong class="weight-600">Los mundo movil carts </strong>  -->
                {{$t("cart.description_my_carts")}}.
            </p>
            <table class="table text-center" v-if="!loading">
                <thead>
                    <tr> 
                        <th scope="col" class="title-16 mb-0 text-start">{{ $t("cart.branch") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.amount") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.quantity") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.action") }}</th>
                    </tr>
                </thead>
                <tbody>
                <tr :class="{'table-active-cart':branchSelected.codplace ===  cart.codplace} " style="cursor: pointer"
                    @click="selectPlace(cart.codplace)"
                    v-for="cart in filteringCarts" :key="cart.codplace">
                    <td class="text-start" style="max-width:280px"> 
                        <div class="d-flex">
                            <div class="d-flex flex-column text-capitalize">
                                <strong class="color-dark-main-navy d-flex title-15 mb-1">
                                    <icon-pin :color="'var(--light-main-red)'" class="me-1" :size="15"/>
                                    {{ getBranch(cart.codplace).description | textFormat}} 
                                </strong>          
                                <span class="subtitle-14 color-dark-gray-200 d-none d-md-block text-limited">{{ getBranch(cart.codplace).address | textFormat}} </span>
                            </div>           
                        </div>
                    </td>
                    <td class="subtitle-14 text-nowrap">{{cart.total_amount | moneyFormat}}</td>
                    <td class="subtitle-14">{{ cart.total_items_products}} </td>
                    <td @click="$emit('deleteCart', cart.codplace)">
                        <icon-trash :color="'var(--gray-1001)'" class="hover-main-red" :size="20" viewBox="0 0 200 200"/>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="table text-center" v-else>
                <thead>
                    <tr> 
                        <th scope="col" class="title-16 mb-0 text-start">{{ $t("cart.branch") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.amount") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.quantity") }}</th>
                        <th scope="col" class="title-16 mb-0">{{ $t("cart.action") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in 4" :key="item">
                        <td><div class="skeleton"></div></td>
                        <td><div class="skeleton"></div></td>
                        <td><div class="skeleton"></div></td>
                        <td><div class="skeleton"></div></td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-else>
            <alert-warning :show="true" class="mt-2 p-0" @showAlert="showAlert = false" :size="30" :showCounter="false" :title="'cart.still_you_do_not_have_world_mobile_cart_in_any_of_our_branches'"/>
            <h6 class="mt-1 mb-2">{{$t("cart.select_store")}}</h6>
            <main-select :options="filterOptions" :selectedOption="selectedOption" summaryRef="select-branch" @clickHandler="setBranch"/>
            <div class="d-flex justify-content-end mt-4">
                <main-button :title="'cart.go_to_purchase'" :icon="true" @click="$router.push('/shop')">
                    <icon-search :size="15" class="me-1" :color="'#fff'"/>
                </main-button>
            </div>
        </template>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import { mapGetters } from "vuex";
import IconTrash from '../../../../common/svg/iconTrash.vue';
import IconPin from '../../../../common/svg/iconPin.vue';
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue';
import { CartService } from "../../services/cart-service";
import IconSearch from '../../../../common/svg/iconSearch.vue';
import MainSelect from '../../../../common/components/selects/MainSelect.vue';
const service = new CartService();
export default {
    components: { MainModal, IconTrash, IconPin, MainButton, AlertWarning, IconSearch, MainSelect },
    name: "MyCarts",
    computed:{
        filteringCarts() {
            const carts = []
            if(this.carts){
                this.carts.forEach(cart => {
                    if (cart.products.length)
                    carts.push(cart)
                })
            }
            return carts;
        },
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            branches: "branches/branches",
            isLogged: "auth/isLogged",
            userData: "auth/userData",
            getAddresses: "account/getAddresses",
        }),
        selectedOption(){
        const newObject = {
            value: this.branchSelected.codplace,
            title: this.branchSelected.description

        };
        return newObject
        },
        filterOptions(){
        const newObject = [];
        const options = this.branches;
        for(let option of options) {
            newObject.push( {
                value: option.codplace,
                title: this.$options.filters.textFormat(option.description)
            });
        }
        return newObject
        },
    },
    data() {
        return {
            carts: [],
            loading: false,
        }
    },
    methods:{
        getBranch(codplace) {
            if(this.branches)
            return this.branches.find(branch => branch.codplace === codplace);
        },
        async selectPlace(branch){
            if(this.branchSelected.codplace != branch){
                await this.$store.commit('branches/SET_BRANCH', branch);
                this.$store.commit("cart/CLEAR_CART")
                this.$store.dispatch('cart/getCartByBranch', {codplace:branch}); 
            }
                if (this.isLogged){
                    await this.$store.dispatch('account/addressWithLimitAvaliable', {addresses : this.addresses, options : {calculate_prices: 0}})
                }
            this.$emit("closeModalMyCart")
        },
        async setBranch(branch){
            try{
                if (!branch) {
                    this.showError = true
                    return
                }
                    let oldBranch = this.branchSelected.codplace
                    await this.$store.commit('branches/SET_BRANCH', branch.value);
                    if(oldBranch != branch.value){
                        this.$store.commit("cart/CLEAR_CART")
                        this.$store.dispatch('cart/getCartByBranch', {codplace:branch.value}); 
                    }
                    if (this.isLogged){
                    await this.$store.dispatch('account/addressWithLimitAvaliable', {addresses : this.addresses, options : {calculate_prices: 0}})
                    }
                this.$emit('closeModal')
            }catch (e) {
                console.error(e)
                // this.loading = false
            }finally{
                // this.loading = false
            }
        },
    },
    async created(){
        let carts = [];
        try {
            this.loading = true
            if (this.isLogged){
                carts = await service.getAllMyCarts(this.userData.entity.id);
            }else{
                this.branches.forEach(branch=>{
                    let products = JSON.parse(localStorage.getItem('shopping_'+branch.codplace));
                    let total_amount = 0
                    if(products?.length)
                    for (let i = 0; i < products.length; i++) {
                        const element = products[i];
                        total_amount += element.total_amount
                    }
                    let cart = { codplace: branch.codplace, products:  JSON.parse(localStorage.getItem('shopping_'+branch.codplace)),  total_amount};
                if (cart.products)
                    carts.push(cart)
                })
            }
            this.carts = carts
        } catch (error) {
            console.log(error)
        }finally{
            this.loading = false
        }
    }
}
</script>

<style scoped>
    .table-active-cart {
        background: var(--light-gray-100) !important;
    }
    .table td, .table th{
        vertical-align: middle !important;
        border-color: var(--light-gray-100);
    }
    .table>:not(:first-child){
        border-top: 0px;
    }
    .table>thead {
        border-top: 1px solid var(--light-gray-501);
    }
    tbody > tr:hover{
        background: #f8f9f952 !important;
    }
    .skeleton{
        height: 20px;
        border-radius: 5px;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgb(198 212 219 / 20%) 8%, rgb(198 212 219 / 30%) 18%, rgb(198 212 219 / 20%) 33%);
        background-size: 800px 100px;
        animation: wave-squares 1.5s infinite ease-out;
    }
    @keyframes wave-squares {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
</style>