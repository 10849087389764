<template>
    <div class="position-relative">
        <div class="row">
            <div v-if="(products.length > 0 || loadingCart)" class="cart-header position-inherit col-md-12 col-lg-12 col-xl-9 mb-0 mb-md-4 bg-white" :class="{'low-index': showFixed}">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <icon-invoice v-if="selectedQuotation" class="me-2 me-md-3" :size="18" color="var(--blue-600)"/>
                        <icon-cart v-else class="me-2 me-md-3" />
                        <h5 class="title-22 weight-600 mb-0 d-flex">
                            <span class="d-none d-md-block">{{selectedQuotation? 'Mi cotización' : $t("cart.my_shopping_cart")}}:</span>
                            <span class="d-block d-md-none">{{$t("cart.my_cart")}}: </span>
                            <a v-if="selectedQuotation" @click="goToQuotationPosition(selectedQuotation)" class="ms-2">{{selectedQuotation}}</a>
                            <b class="subtitle-22 color-dark-gray-300 mb-0 weight-500 ms-2 ps-1" v-else>{{branchSelected.description | textFormat}}</b>
                        </h5>
                    </div>
                    <a @click.prevent="deleteProducts" class="clear-all position-relative hover-underline">
                        <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
                        <span class="size-15 d-none d-sm-flex">{{$t(selectButton ? "cart.delete_selection" : selectedQuotation ? "account.quit_quote" : 'cart.delete_cart')}}</span>
                    </a>
                </div>
            </div>            
            <div v-if="getProductsCart.length" class="col-md-12 col-lg-9 position-relative" :class="'mobile-space'">
                <alert-warning :show="isChanged" :showCounter="false" :content="true" class="p-2" actionTitle="Deshacer" @handlerAction="showModalCancel = true">
                    <div class="d-flex align-items-center justify-content-between">
                        <span class="subtitle-14 color-warning-900 weight-500 size-14">
                            Tienes cambios sin guardar en esta cotización.
                            <a href="#" @click.prevent="createQuotation">Click para actualizar</a>
                        </span>
                    </div>
                </alert-warning>
                <product-table isCart :products="getProductsCart" @showFixed="(data) => { showFixed = !data }" @showQuickView="showQuickView"
                    @selectActive="(data)=> {selectButton = data}" :deleteSelectionAction="deleteSelection" @showShare="showShare"/>
            </div>
            <div v-else-if="!loadingCart && products.length === 0" class="col-md-12 col-xl-9 col-lg-9 d-flex flex-column align-items-center">
                <div class="d-flex align-items-center me-auto mb-sm-1">
                    <icon-cart class="me-2 me-md-3"/>
                    <h5 class="title-22 weight-600 mb-0 d-flex">
                        <span class="d-none d-md-block">
                            {{$t("cart.my_shopping_cart")}}: 
                        </span>
                        <span class="d-block d-md-none">
                            {{$t("cart.my_cart")}}: 
                        </span>  
                        <b class="subtitle-22 color-dark-gray-300 mb-0 weight-500 ms-2 text-capitalize">{{branchSelected.description | textFormat}}</b>
                    </h5>
                </div>
                <hr class="w-100" style="height: 2px;"/>
                <icon-big-empty-cart :size="100" @mousemove.native="moveElements"/>
                <h6 class="mt-5 mb-2 title-20 mb-0">{{$t("cart.empty_cart")}}</h6>
                <p class="text-center size-15 mb-0"> 
                    {{$t("cart.your_cart_at_the_branch")}} <span class="weight-600">{{branchSelected.description | textFormat}}</span> {{$t("cart.it_is_empty")}}
                </p>
                <span class="color-dark-gray-200 size-14 mb-3">
                    {{$t('cart.change_branch_1')}} <b class="change-branch-address cursor-pointer weight-400 color-blue-600" @click="showChangeBranch = true">{{$t('cart.change_branch_2')}}</b>?
                </span>
                <main-button v-if="!mobile" @click="() => {$router.push('/shop')}" class="mb-4 mb-md-0" icon title="cart.go_shopping" id="go-shipping">
                    <icon-search :color="'#fff'" :size="15" class="me-2"/>
                </main-button>
            </div>
            <div class="col-md-12 col-lg-9" v-else />
            <div class="cart-subtotal d-none d-lg-block col-md-12 col-lg-3 align-items-center">
                <div>
                    <subtotal @buy="buy" @createQuotation="createQuotation" @openCarts="showCarts = true" :bottonLabel="bottonLabel" @print="print" class="subtotal mt-5"/>
                    <card style="margin-top: 30px">
                        <div class="d-flex flex-column align-items-center">
                            <a href="#" class="size-14 d-flex" :class="{'disable-button': products.length == 0 || !isLogged}" @click.prevent="createQuotation">
                                <icon-plus-invoice :size="16" :color="'var(--blue-600)'" class="me-2"/>
                                {{$t('cart.create_quotation')}}
                            </a>
                            <hr class="w-100 my-2 bg-dark-gray-200">
                            <router-link to="/account/quotes" class="hover-main-red size-14 d-flex position-relative" id="btn-go-to-quotation">
                                <icon-invoice :size="10" :color="'var(--blue-600)'" class="me-2"/>
                                <span class="main-link">{{$t('cart.go_to_quotes')}}</span>
                            </router-link>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <div class="subtotal-mobile d-block d-lg-none pb-3 col-12" :style= "!mobile ? 'bottom: 0px !important;' : 'bottom: 10px;'" :class="{'subtotal-mobile-low':getProductsCart.length > 3 }">
            <subtotal @buy="buy" @openCarts="showCarts = true" :bottonLabel="bottonLabel" class="card-subtotal-mobile" isMobile />
        </div>
        <my-carts v-if="showCarts" @closeModalMyCart="showCarts = false" :carts="carts" @deleteCart="confirmDeleteCart" />
        <confirm-delete-cart v-if="showConfirmDelete" @closeModal="showConfirmDelete = false" @confirmDelete="deleteCart" />
        <quantity-alert v-if="quantityNotAvailable" :products="productsError" :existence="existenceByBranch" @closeModal="quantityNotAvailable = false" />
        <share v-if="share" @closeModal="share = false" :product="shareProduct" />
        <select-branch v-if="showChangeBranch" @closeModal="showChangeBranch = false" />
        <error-cart v-if="error.show" @closeModal="error.show = false" @confirm="buyWithDiference" title="Error procesando su orden" :description="error.description" :contentHTML="error.contentHTML"/>
        <quotation-created :quotationID="quoteCreated.quotation" @closeModal="quoteCreated.show = false" v-if="quoteCreated.show" />
        <confirm-cancel v-if="showModalCancel" @confirm="cancelUpdate" @closeModal="showModalCancel = false" />
        <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainButton from '../../../common/components/buttons/MainButton.vue';
import ConfirmDeleteCart from '../components/modals/ConfirmDeleteCart.vue';
import QuickView from '../../products/components/quick-view/QuickView';
import MyCarts from '../components/modals/MyCarts.vue';
import Subtotal from '../components/Subtotal.vue';

import { cartMixin } from "../helpers/mixins/cart-mixin";
import { quotesMixin } from '../../account/helpers/mixins/quotes-mixin';
import ProductTable from '../components/ProductTable.vue';
import IconSearch from '../../../common/svg/iconSearch.vue';
import QuantityAlert from '../components/QuantityAlert.vue';
import IconCart from '../../../common/svg/iconCart.vue'
import IconTrash from '../../../common/svg/iconTrash.vue';
import Share from '../../products/components/modals/Share.vue';
import IconBigEmptyCart from '../assets/svg/IconBigEmptyCart.vue';
import SelectBranch from '@/modules/branches/components/SelectBranch.vue'
import ErrorCart from '../components/modals/ErrorCart.vue';
import QuotationCreated from '../components/modals/QuotationCreated.vue';
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue';
import ConfirmCancel from '../../account/components/quotes/modals/ConfirmCancel.vue';
import IconInvoice from '../../account/assets/svg/iconInvoice.vue';
import Card from '../../../common/components/cards/Card.vue';
import IconPlusInvoice from '../../../common/svg/iconPlusInvoice.vue';

export default {
    name: "Cart",
    components: { 
        MainButton, 
        Share, 
        MyCarts, 
        Subtotal, 
        ConfirmDeleteCart, 
        ProductTable, 
        SelectBranch, 
        QuantityAlert, 
        IconInvoice,
        IconSearch, 
        IconTrash, 
        IconBigEmptyCart, 
        IconCart, 
        ErrorCart, 
        QuotationCreated, 
        AlertWarning, 
        ConfirmCancel,
        Card,
        QuickView,
        IconPlusInvoice
    },
    computed:{
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            amount: "cart/amount",
            products: "cart/getProducts",
            isLogged: "auth/isLogged",
            userData: "auth/userData",
            cart: "cart/cart",
            loadingCart: "cart/loadingCart",
            selectedQuotation: "account/selectedQuotation",
            changedQuotes: "account/changedQuotes",
            quotes: "account/quotes",
            employeeActiveCode: 'auth/employeeActiveCode',
            mobile: 'common/mobile',
        }),
        isChanged(){
            let index = this.changedQuotes.findIndex(item=> item.quotation == this.selectedQuotation)
            return index != -1
        },
        bottonLabel(){
            if(this.loadingCart){
                return 'cart.verifying'
            }else if(this.employeeActiveCode){
                return  this.loading ? 'cart.verifying'
                    : this.isChanged ? 'cart.refresh_cart'
                    : 'cart.make_a_purchase';
            }else{
                if(this.mobile){
                    return  this.loading ? 'cart.verifying' : this.isChanged ? 'cart.refresh_cart' : this.bottonLabelMobile;
                }
                return this.loading ? 'cart.verifying' : this.isChanged ? 'cart.refresh_cart' : 'cart.make_a_purchase';
            }
        },
        getProductsCart(){
            if(this.selectedQuotation){
                let products = this.products
                const quotation = this.changedQuotes.find(quotation=> quotation.quotation == this.selectedQuotation)
                if(quotation){
                    quotation.products.forEach(product => {
                        const index = products.findIndex(e => e.cod_articulo == product.codarticulo)
                        if(index == -1){
                            products.push({
                                cod_articulo: product.codarticulo,
                                product: {...product.product, price: product.product.price},
                                total_amount: product.amount,
                                quantity: product.quantity
                            })
                        }
                    });
                }
                return products
            }
            return this.products
        },
    },
    data() {
        return {
            showCarts: false,
            quickView: { value: false, product: null },
            showConfirmDelete: false,
            carts: [],
            cartSelectDelete: false,
            bottonLabelMobile: 'cart.buy_checkout',
            productsError: [],
            existenceByBranch: [],
            quantityNotAvailable: false,
            selectButton: false,
            deleteSelection:false,
            showSubtotal: false,
            shareProduct: null,
            share: false,
            showChangeBranch: false,
            quoteCreated: {
                show: false,
                quotation: null
            },
            showModalCancel: false,
            loading: false,
            showFixed: true,
        }
    },
    mixins:[cartMixin, quotesMixin],
    methods: {
        confirmDeleteCart(codeBrach) {
            this.cartSelectDelete = codeBrach
            this.showConfirmDelete = true
        },
        async deleteCart() {
            try {
                this.titleButton = 'cart.loading'
                await this.$store.dispatch("cart/clearCart")
                
            } catch (error) {
                console.log(error)
            }finally{
                this.titleButton = 'auth.confirm'
                this.showConfirmDelete = false
            }
        },
        showShare(product){
            this.share = true
            this.shareProduct = product;
        },
        getDate() {
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async print() {
            let products = []
            let d = new Date(); // for now
            let hour = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
            
            for (let i = 0; i < this.products.length; i++) {
                const product = this.products[i];
                products.push({amount: product.total_amount,codarticulo: product.product.codarticulo,product: product.product, price_sold: product.product.price, 
                quantity: product.quantity})
            }
            let order = {
                codplace: this.branchSelected.codplace,
                order_primary:{
                    order_document_id: "XXXXXXXXXX",
                    amount: this.cart.total_amount,
                    payments: [
                        {type: "No pagado"}
                    ],
                    delivery_amount: 0,
                },
                option_id: 3,
                amount_without_tax: this.cart.total_amount - (this.cart.total_amount * 0.18),
                amount: this.cart.total_amount,   
                order_details: products,
                order_document_id: "XXXXXXXXXX",
                created_at: this.getDate()+'T'+hour,
                tax: (this.cart.total_amount * 0.18)
            }
            this.$store.commit('account/SET_INVOICE', order)
            this.$router.push({path: '/account/invoice'})
        },
        moveElements(e){
            document.querySelectorAll('.object').forEach((move) => {
                let moveValue = move.getAttribute("data-value")
                let moveValueY = move.getAttribute("data-value-y")
                if(moveValue){
                    let x = ( e.clientX * moveValue ) / 100
                    let y = ( e.clientY * moveValue ) / 100
                    move.style.transform = `translateX(${x}px) translateY(${y}px)`
                }else if(moveValueY){
                    let y = ( e.clientY * moveValueY ) / 100
                    let x = ( e.clientX * moveValueY ) / 100
                    move.style.transform = `translateY(${y + (x - 5)}px)`
                }
            })
        },
        async goToQuotationPosition(selectedQuotation){
                this.$router.replace({ path: `/account/quotes?q=${selectedQuotation}` }).catch(() => { });
            },
        // async createQuotation(){
        //     await this.$store.dispatch("common/getConfigurations");
        //     const validateCustomer = !this.userData.entity.activated_employee && this.configurations.general.web_page.active_for_customer && !this.employeeActiveCode
        //     const validateEmployee = this.employee.entity.activated_employee && this.configurations.general.web_page.active_for_employee
        //     console.log(this.isPageDisable , validateCustomer , validateEmployee);
        //     if(this.isPageDisable || validateCustomer || validateEmployee){
        //         this.$store.commit("common/SET_DISABLE_MODAL", true);
        //         return;
        //     }
        //     if(!this.isLogged) {
        //         this.$store.commit("auth/OPEN_MODAL", true);
        //         return;
        //     }
        //     if(this.products.length == 0){
        //         let notify = {
        //                 show: true,
        //                 type: 'warning',
        //                 contentHTML: 
        //                 `   <span class="size-14 color-dark-gray-200">
        //                         No tienes productos para cotizar.
        //                     </span>
        //                 `
        //             }
        //             this.$store.commit("common/SET_SHORT_NOTIFY", notify);
        //         return;
        //     }
        //     try {
        //         this.loading = true
        //         if(this.selectedQuotation){
        //             const quotation = this.changedQuotes.find(quotation=> quotation.quotation == this.selectedQuotation)
        //             if(quotation){
        //                 const validate = quotation.products.filter(item=> item.delete == true)
        //                 if(validate.length > 0){
        //                     quotation.products.forEach(() => {
        //                         const index = this.products.findIndex(e => e.delete == true)
        //                         if(index == -1){
        //                             this.products.splice(index, 1)
        //                         }
        //                     });
        //                 }
        //             }
        //             let params = {
        //                 products: this.products,
        //                 id: this.selectedQuotation,
        //                 branch: this.branchSelected.codplace
        //             }
        //             await this.$store.dispatch('account/updateQuotationFromCart', params)
        //             this.$store.dispatch('cart/clearCart')
        //             let notify = {
        //                 show: true,
        //                 type: 'success',
        //                 contentHTML: 
        //                 `   <span class="size-14">
        //                         La cotizacion <a>${this.selectedQuotation}</a> a sido actualizada con exito.
        //                     </span>
        //                 `
        //             }
        //             this.$store.commit("common/SET_SHORT_NOTIFY", notify);
        //             this.$store.commit('account/SELECT_QUOTATION', null)
        //             return
        //         }
        //         const response = await this.$store.dispatch('cart/createQuotation');
        //         this.quoteCreated = { show: true, quotation: response.document_id }
        //     } catch (error) {
        //         let errorData = {
        //             show: true,
        //             title: `Ha ocurrido un error.`,
        //             description: error.response.data.message
        //         }
        //         this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
        //     } finally{
        //         this.loading = false
        //     }
        // },
        async cancelUpdate(){
            try {
                await this.$store.dispatch('account/getQuotationByID', {id: this.selectedQuotation, refresh: true})
                let quotation = this.quotes.data.find(quote=> quote.order_token_id == this.selectedQuotation)
                await this.moveToCart(quotation, true)
                this.$store.commit('account/REMOVE_FROM_CHANGED_QUOTATION', this.selectedQuotation)
                this.showModalCancel = false
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false;
            }
        },
        async deleteProducts(){
            if(this.isPageDisable){
                this.$store.commit("common/SET_DISABLE_MODAL", true);
                return;
            }
            if(this.selectButton){
                this.deleteSelection = !this.deleteSelection;
            }else if(this.selectedQuotation){
                try {
                    await this.$store.dispatch('cart/clearCart');
                    this.$store.commit('account/SELECT_QUOTATION', null)
                } catch (error) {
                    console.log(error)
                }
            }else{
                this.showConfirmDelete = true
            }
        },
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
    },
    async created(){
        localStorage.removeItem('addressSelected')
        let product = this.products.find(item=>item.product.type_id == 4)
        this.$store.commit("checkout/RESET_STATE")
        this.$store.commit("checkout/RESET_STRIPE_ELEMENTS")
        if(product){
            try {
                await this.$store.dispatch("cart/getMyCart")
            } catch (error) {
                console.log(error)
            }
        }
    },
}
</script>

<style scoped>
    .cart-header {
        height: 40px;
        padding-block: 20px;
    }
    .clear-all > span {
        color: var(--blue-600);
        transition: all .15s ease-in-out !important;
    }
    .clear-all:hover > span {
        margin-right: 21px;
        color: var(--main-red) !important;
    }
    .clear-all > svg {
        position: absolute;
        top: -14px;
        right: 0px;
        transition: all .15s ease-in-out, fill .05s ease-in-out;
        width: 25px !important;
        opacity: 1;
    }
    .clear-all:hover > svg {
        color: var(--main-red) !important;
        opacity: 1;
    }
    @media (min-width: 576px) {
        .clear-all > svg {
            top: 2px;
            width: 18px !important;
            opacity: 0;
        }
    }
    @media (max-width: 576px) {
        h6 {
            margin-top: 2rem !important;
        }
        .cart-header {
            z-index: 4; 
            top: 59px; 
            padding-bottom: 40px;
        }
    }
    .hover-color-svg svg{
        fill: var(--blue-600);
        transition: var(--transition-1);
    }
    .hover-color-svg:hover svg {
        fill: var(--main-red);
        transition: var(--transition-1);
    }
    @media (max-width: 991px) {
        .subtotal {
            box-shadow: none;
        }
    }
    .icon-cart::v-deep {
        width: 30px;
    }
    @media (max-width: 1200px) {
        .cart-subtotal > div >.card-container {
            width: 100%;
            margin: 0;
            margin-left: auto;
            margin-bottom: 0px !important;
            padding: 10px 10px !important;
        }
    }
    @media (min-width: 1200px) {
        .cart-subtotal > div >.card-container {
            width: 90%;
            margin-right: 0;
        }
    }
    @media (min-width: 1400px) {
        .cart-subtotal > div >.card-container {
            width: 80%;
            margin-right: 0;
        }
    }
    .cart-subtotal > div {
        position: sticky;
        top: 13%;
    }
    .cart-subtotal > div > .card-container {
        padding: 15px 35px;
        margin-bottom: 15px;
    }
    @media (max-width: 728px) {
        .title-22, .subtitle-22 {
            font-size: 16px !important;
        }
        a {
            font-size: 12px;
            white-space: nowrap;
            font-weight: 600;
        }
        .icon-cart::v-deep {
            width: 25px ;
        }
    }
    .show-order-summary{
        background: white;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
        padding: 3%;
        border-radius: 7px;
    }
    .iconArrow {
        transform: rotate(90deg);
        transition: var(--transition-1);
    }
    .iconRotate {
        transform: rotate(270deg) !important;
    }

    @media (max-width: 992px) {
        .card-subtotal-mobile {
            background: transparent;
            box-shadow: none;
        }
    }

    @media (max-width: 475px) {
        .mobile-space {
            margin-top: 25px;
        }
    }
    .subtotal-mobile {
        position: fixed;
        bottom: 10px;
        left: 0px;
        width: 100vw;
        background: #ffffff;
        z-index: 3;
        padding: 10px;
        box-shadow: 0 -5px 5px -5px #212e3d26;
        /* margin: -10px; */
    }

    .subtotal-mobile-low {
        position: sticky !important;
        width: 98vw !important;
        bottom: 0px !important;
    }
    .change-branch-address:hover {
        color: var(--main-red) !important;
        text-decoration: underline !important;
    }
    .delete-cart-button > svg {
        animation: show-icon-delete 0.5s;
    }
    .delete-cart-button:hover > svg {
        transition: all .15s ease-in-out !important;
    }
    .delete-cart-button:hover{
        text-decoration: underline !important;
        animation: transition-delete-all 0.5s;
    }
    @keyframes show-icon-delete {
        from {
            opacity: 0;
        }to {
            opacity: 1;
        }
    }
    @keyframes transition-delete-all {
        from {
            transform: translateX(5px);
        }to {
            transform: translateX(0px);
        }
    }
    .loading::v-deep{
        position: absolute;
        width: 101%;
        height: 80%;
        z-index: 3;
    }
    .cart-mobile{
        margin-bottom: 130px;
    }
    .disable-button{
        cursor: no-drop !important;
        color: var(--dark-gray-200) !important;
    }
    .disable-button > svg{
        fill: var(--dark-gray-200) !important;
    }
    /* #btn-go-to-quotation > span{
        transition: all .15s ease-in-out;
    }
    #btn-go-to-quotation:hover > span{
        color: var(--main-red) !important;
        margin-right: 18px;
    }
    #btn-go-to-quotation > svg {
        position: absolute;
        top: 5px;
        right: 0px;
        transition: all .15s ease-in-out, fill .05s ease-in-out;
        opacity: 0;
    }
    #btn-go-to-quotation:hover > svg {
        opacity: 1;
    } */
</style>
