<template>
    <main-confirm @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" :confirm-title="'cart.delete_cart'" description="cart.are_you_sure_you_want_to_delete_this_cart"
    :secondTitle="'cart.all_your_products_will_be_removed'">
        <icon-delete-cart :size="90"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import IconDeleteCart from '../../assets/svg/iconDeleteCart.vue';

export default {
    name: "ConfirmDeleteCart",
    components: {
        MainConfirm,
        IconDeleteCart
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        }
    }
}
</script>
