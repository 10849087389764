<template>
<div class="product-table">
    <table class="d-none d-md-block table">
        <thead style="border-bottom: 2px solid #a5b2b717 !important" :class="{'low-index': !showConfirmDelete || !showConfirmDeleteProduct }">
            <tr>
                <th scope="col" class="weight-600">
                    <div class="d-flex">
                        <main-checkbox v-if="isCart" :value="selectedAll" @change="selectAll" id="select-all" class="me-3" /> {{$t("product.product_details")}}    
                    </div> 
                </th>
                <th scope="col" class="text-center weight-600">{{$t("product.quantity")}}</th>
                <th scope="col" class="text-center weight-600">{{$t("product.total")}}</th>
                <th scope="col" class="text-center weight-600">{{$t("product.action")}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="{product} in products" :key="product.codarticulo"
                :class="[isProductChanged(product.codarticulo, selectedQuotation) ? isProductChanged(product.codarticulo, selectedQuotation).delete ? 'product-deleted' : 'product-changed' : '']">
                <td class="td-responsive" id="td-product-short-details">
                    <div class="d-flex">
                        <main-checkbox v-if="isCart" :id="`select-product-${product.codarticulo}`" class="me-3" :values="selectedProducts" :value="product.codarticulo" isList
                        @change="selectProduct(product.codarticulo)" />  
                        <product-short-details :product="product || {}" :isCart="isCart" />
                    </div>
                </td>
                <td class="td-responsive align-middle text-center" id="td-quantity">
                    <product-quantity-input :product="product" :productQuantity="getQuantity(product)" @deleteProduct="(data) => { deleteConfirm(data) }" />
                </td>
                <td class="td-responsive align-middle title-15 weight-700 text-center">
                    <div v-if="product.special_price && (product.quantity_required_special_price != null && product.quantity_required_special_price <= getQuantity(product))" class="d-flex flex-column align-items-center justify-content-center">
                        <h6 class="d-flex flex-column align-items-start subtitle-16 mb-0">
                            <span class="title-15 mb-0 weight-700">
                                <b>{{showValueMoney()}}$</b>
                                <b class="color-light-main-red weight-700"> {{parseFloat((getQuantity(product)) * product.special_price ).toFixed(2) | moneyFormat(0, false)}}</b>
                            </span>
                            <span class="special-price color-dark-gray-200">{{product.price | moneyFormatMultiply(getQuantity(product))}}</span>
                        </h6>
                    </div>
                    <div v-else-if="currency">
                        <b>{{ currency ? currency.simbol : showValueMoney()}}</b>
                        <b v-if="getQuantity(product)" class="color-light-main-red weight-700">
                            {{parseFloat((getQuantity(product)) * product.price).toFixed(2) | moneyFormat(0, false)}}
                        </b>
                        <b v-else class="color-light-main-red weight-700">{{product.price | moneyFormat(0, false)}}</b>
                    </div>
                </td>
                <td class="td-responsive align-middle text-center">
                    <div class="d-flex justify-content-between">
                        <icon-share :size="25" @click.native="$emit('showShare', product)" />
                        <icon-favourite :size="25" @click.native="addToWishList(product)" :class="[{'favorite-active': checkInFavorites(product)}]"/>
                        <icon-cart :size="28" @click.native="increment(product)" v-if="!isCart" :class="[getQuantity(product) >= product.existence ? 'btn-disabled' : '']"/>
                        <icon-trash :size="32" @click.native="deleteConfirm(product)" v-if="isCart"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-md-none mt-sm-4">
        <swipe-actions ref="swipe_actions" class="swiper-container" :products="products" :isCart="isCart" :selectedProducts="selectedProducts"
        @addToWishList="addToWishList_" @deleteProduct="(data) => { deleteConfirm(data) }" @showShare="(product) => { $emit('showShare', product) }"
        @selectProduct="(product) => { selectProduct(product) }" @showQuickView="(data) => { $emit('showQuickView', data)}" />
    </div>
    <confirm-delete-product v-if="showConfirmDeleteProduct" @closeModal="showConfirmDeleteProduct = false" @confirmDelete="deleteConfirmed" :loading="loadingDelete"/>
    <confirm-delete-selection v-if="showConfirmDelete" @closeModal="showConfirmDelete = false" @confirmDelete="deleteSelection"/>
</div>
</template>

<script>
import ProductShortDetails from './ProductShortDetails.vue'
import ProductQuantityInput from '../../products/components/ProductQuantityInput.vue';
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue';
import SwipeActions from '../../products/components/SwipeActions.vue';
import ConfirmDeleteSelection from '../../cart/components/modals/ConfirmDeleteSelection.vue';
import ConfirmDeleteProduct from '../../products/components/modals/ConfirmDeleteProduct.vue';
import { mapGetters } from "vuex"
import { productMixin } from '../../../modules/products/helpers/mixin/product-mixin';

import IconTrash from '../../../common/svg/iconTrash.vue';
import IconCart from '../../../common/svg/iconCart.vue'
import IconFavourite from '../../../common/svg/iconFavourite.vue'
import IconShare from '../../../common/svg/iconShare.vue';

export default {
    components: { ProductShortDetails, ProductQuantityInput, IconCart, IconFavourite, IconTrash, IconShare, ConfirmDeleteProduct, MainCheckbox, ConfirmDeleteSelection, SwipeActions },
    name: "ProductTable",
    props: {
        products: {
            type: Array,
            required: true
        },
        currency: {
            type: Array
        },
        isCart: {
            type: Boolean,
            default: false
        },
        deleteSelectionAction: {
            type: Boolean
        }
    },
    data() {
        return {
            showConfirmDeleteProduct: false,
            selectedProduct: {},
            selectedProducts: [],
            selectedAll: false, 
            showConfirmDelete: false,
            loadingDelete: false
        }
    },
    computed: {
        ...mapGetters({
            openModal: "wishlist/openModal"
        }),
    },
    mixins: [productMixin],
    methods: {
        async deleteConfirmed() {
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("cart/removeProduct", { cod_articulo: this.selectedProduct.codarticulo, quantity: 0 });
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingDelete = false;
                // console.log(JSON.stringify(this.getProducts.length));
                if(this.mobile)
                window.AddToCartEvent.postMessage(JSON.stringify(this.getProducts.length));
            }
            this.showConfirmDeleteProduct = false;
        },
        deleteConfirm(product) {
            this.selectedProduct = product;
            this.showConfirmDeleteProduct = true;
        },
        selectProduct(codarticulo) {
            const index = this.selectedProducts.indexOf(codarticulo);
            if(index >= 0) {
                this.selectedProducts.splice(index, 1);
            }else {
                this.selectedProducts.push(codarticulo);
            }
            this.$emit("selectActive", this.selectedProducts.length > 0);
        },
        async deleteSelection() {
            if(this.selectedProducts.length === this.products.length){
                await this.$store.dispatch('cart/clearCart');
            }else {
                for (let i = 0; i < this.selectedProducts.length; i++) {
                    const product = this.selectedProducts[i];
                    let newProduct = {
                        cod_articulo: product,
                        quantity: 0
                    }
                    try {
                        await this.$store.dispatch("cart/removeProduct", newProduct);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            this.selectedProducts = [];
            this.$emit("selectActive", false);
            this.showConfirmDelete = false;
        },
        selectAll() {
            this.$emit("selectActive", false);
            this.selectedAll = !this.selectedAll;
            if (this.selectedAll) {
                this.products.map(({ cod_articulo }) => {
                    if (this.selectedProducts.indexOf(cod_articulo) == -1) {//only add it they're not already there
                        this.selectedProducts.push(cod_articulo)
                    }
                });
            }else {
                this.selectedProducts = [];
            }
        },
        addToWishList_(product) {
            this.addToWishList(product)
        },
        productChanged(product) {
            const product_changed = this.isProductChanged(product.codarticulo, this.selectedQuotation);
            if (!product_changed) return ''
            return product_changed?.delete ? 'product-deleted' : 'product-changed';
        }
    },
    watch: {
        showConfirmDeleteProduct(val) {
            this.$emit('showFixed', val);
        },
        showConfirmDelete(val) {
            this.$emit('showFixed', val);
        },
        deleteSelectionAction(){
            this.showConfirmDelete = !this.showConfirmDelete
        },
        selectedProducts(val){
            this.selectedAll = val.length === this.products.length;
            if (val.length === 0 ) this.$refs["swipe_actions"].selectView.show = false;
        }
    }
}
</script>

<style scoped>
    svg {
        fill: var(--gray-1001);
        cursor: pointer;
        transition: var(--transition-1);
    }
    svg:hover {
        fill: var(--light-main-red);
    }
    table {
        color: var(--dark-main-navy);
        font-size: 15px;
        font-weight: 400 !important;
    }
    thead.low-index {
        z-index: 7;
    }
    @media (max-width: 768px) {
        table {
            display: none;
        }
    }
    .btn-disabled  > svg {
        cursor: no-drop !important;
        fill: var(--gray-50) !important;
    }
    td {
        padding: 2% 0px;
        border-bottom: 1px solid var(--gray-50);
    }
    th {
        padding: 2% 0%;
    }
    .td-responsive:first-child {
        width: 30%;
    }
    .td-responsive {
        width: 15%;
    }
    .favorite-active {
        fill: var(--light-main-red);
    }
    .special-price {
        text-decoration: line-through;
    }
    thead {
        position: sticky;
        top: calc(7% - 6px);
        background: white;
        z-index: 3;
    }
    .quantity-input-container::v-deep {
        border-radius: 50px;
        max-width: 120px;
    }
    .product-changed > #td-product-short-details > div > .product-short-details::v-deep > div 
    .container-img-product{
        border-color: var(--warning-900);
    }
    .product-changed > #td-product-short-details > div > .product-short-details::v-deep > div
    .container-img-product > .icon-warning{
        display: block !important;
    }
    .product-changed > #td-product-short-details > div > .product-short-details::v-deep > div:nth-child(2)
    > div > .container-product-description > h6{
        color: var(--warning-900);
    }
    .product-changed #td-quantity .quantity-input-container{
        border-color: var(--warning-900);
    }
    .product-changed #td-quantity .quantity-input-container::v-deep input{
        border-color: var(--warning-900) !important;
    }
    /* deleted product styles */
    .product-deleted > #td-product-short-details > div > .product-short-details::v-deep > div:nth-child(2)
    > div > .container-product-description > h6{
        color: var(--main-red); 
    }
    .product-deleted #td-quantity .quantity-input-container{
        border-color: var(--main-red); 
    }
    .product-deleted #td-quantity .quantity-input-container::v-deep input{
        border-color: var(--main-red) !important;
    }
    .main-confirm {
        z-index: 8;
    }
</style>
