<template>
    <div>
        <swipe-list ref="list" :disabled="(!isCart || disable)" :items="products" item-key="id" :revealed.sync="revealed" :threshold="swiperThreshold">
            <template v-slot="{ item }">
                <div class="my-3" v-long-press="800" @long-press-start="enableSelectView(item.product)">
                    <div v-if="selectView.show" class="d-flex ps-3 pe-1" @click="$emit('selectProduct', item.product.codarticulo)">
                        <main-checkbox isList :values="selectedProducts" :value="item.product.codarticulo" :id="`select-product-${item._id}`"
                        :class="{'pointer-events-none': selectView.show}" class="me-3" />
                        <div class="image-product-container me-3">
                            <image-product :styleObject="{'object-fit': 'contain'}" :source="getImages(item.product.imageGallery)" />
                        </div>
                        <div class="product-info d-flex flex-column justify-content-center">
                            <h3 class="size-14">
                                ({{item.product.codarticulo}}) {{item.product.description | textFormat}}
                            </h3>
                            <h4 class="size-16">
                                <b class="color-main-navy me-2">{{showValueMoney()}}$</b> 
                                <b class="color-main-red">{{parseFloat((getQuantity(item.product)) * getPriceFromCart(item.product)).toFixed(2)}}</b> 
                            </h4>
                        </div>
                    </div>
                    <product-short-list-view v-else :product="item.product" :isCart="isCart" @showShare="(product) => { $emit('showShare', product) }"  
                    @showQuickView="(data) => { $emit('showQuickView', data) }" @deleteConfirm="(data) => { $emit('deleteProduct', data) }" 
                    class="container-short-details" />
                </div>
            </template>
            <template v-slot:left>
                <div class="swipeout-action add-to-wishlist">
                    <div class="action-container h-100 d-flex align-items-center justify-content-center">
                        <icon-favourite :size="30" style="margin-right: 14px;" />
                    </div>
                </div>
            </template>
            <template v-slot:right>
                <div class="swipeout-action remove-from-cart">
                    <div class="action-container h-100 d-flex align-items-center justify-content-center">
                        <icon-trash :size="30" style="margin-left: 14px;" />
                    </div>
                </div>
            </template>
        </swipe-list>
    </div>
</template>

<script>
import { SwipeList } from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import ProductShortListView from './ProductShortListView.vue';
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue';
import ImageProduct from '../../products/components/Image.vue'

import { productMixin } from '../helpers/mixin/product-mixin';

import IconFavourite from '../../../common/svg/iconFavourite.vue';
import IconTrash from '../../../common/svg/iconTrash.vue';

export default {
    name: "SwipeActions",
    components: { SwipeList, ProductShortListView, MainCheckbox, ImageProduct, IconFavourite, IconTrash,  },
    mixins: [productMixin],
    props: {
        products: { type: Array },
        selectedProducts: { type: Array },
        isCart: { type: Boolean }
    },
    data(){
        return {
            swipePosition: 0,
            swiperThreshold: 90,
            disable: false,
            timer: {
                action: null,
            },
            revealed: {},
            paused: {
                swipe: {
                    left: false,
                    right: false,
                },
                color: {
                    left: false,
                    right: false,
                },
                rebound: {
                    left: false,
                    right: false,
                }
            },
            selectView: {
                show: false,
                enable: true,
                selectedProducts: []
            },
            observer: null,
        }
    },
    watch: {
        revealed(val) { //validating if there's anything automatically 
            clearTimeout(this.actionTimer);
            for (const key in val) {
                if (val[key] === 'left') {
                    this.$emit('addToWishList', this.products[key].product);
                }else {
                    this.$emit('deleteProduct', { codarticulo: this.products[key].product.codarticulo });
                }
                this.timer.action = setTimeout(() => {
                    this.$refs.list.closeActions(key);
                }, 100);
            }
        },
        'selectView.show'(show) {
            this.disable = show;
            window.navigator.vibrate(70);
        },
    },
    methods: {
        enableSelectView({ codarticulo }) { //disable swipe actions while we are selecting
            if (!this.selectView.enable) return;
            this.selectView.show = true;
            this.$emit('selectProduct', codarticulo)
        },
    },
    mounted() {
        // Select the node that will be observed for mutations
        const targetNode = this.$refs['list'].$el;
        // Callback function to execute when mutations are observed
        const callback = ( mutationList ) => {
            for (const { target } of mutationList) {
                if (target.classList[0] !== 'swipeout-content') return;
                const styles = target.style.transform;
                this.swipePosition = styles.slice((styles.indexOf('(') + 1), styles.indexOf('p')) * 1;
                this.selectView.enable = this.swipePosition < 8 && this.swipePosition > -8;
                // to pause the main content
                const preventLoop = (condition, target_node, modifier_class, paused_section, modify_section) => {
                    if (condition){ // preventing the class assignment to loop over the observer...
                        if (!this.paused[paused_section][modify_section]) target_node.classList.add(`${modifier_class}-${modify_section}`);
                        this.paused[paused_section][modify_section] = true;
                    }else {
                        if (this.paused[paused_section][modify_section]) target_node.classList.remove(`${modifier_class}-${modify_section}`);
                        this.paused[paused_section][modify_section] = false;
                    }
                }
                
                preventLoop(this.swipePosition > 110, target, 'pause-movement', 'swipe', 'left')
                preventLoop(this.swipePosition < -100, target, 'pause-movement', 'swipe', 'right')
                
                preventLoop(this.swipePosition > this.swiperThreshold, target.parentNode, 'action-active', 'color', 'left')
                preventLoop(this.swipePosition < -1 * this.swiperThreshold, target.parentNode, 'action-active', 'color', 'right')
                
                // preventLoop(this.swipePosition === 0 && this.oldSwipe > 12, target, 'rebound', 'rebound', 'left')
                // preventLoop(this.swipePosition === 0 && this.oldSwipe < -12, target, 'rebound', 'rebound', 'right')
            }
        };
        // Create an observer instance linked to the callback function
        this.observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        this.observer.observe(targetNode, { attributes: true, childList: true, subtree: true });
    },
    destroyed() {
        clearTimeout(this.timer.action);
        this.observer.disconnect();
    },
}
</script>
<style scoped>
.swipeout-list.swiper-container::v-deep .swipeout-list-item {
    border-top: 1px solid var(--gray-100);
}
.swipeout-list.swiper-container::v-deep .swipeout-list-item:first-child {
    border-top: 0 !important;
}
.swipeout-list::v-deep .swipeout-content {
    padding-inline: 14px;
}
.image-product {
    width: 105px;
    height: 105px;
}
.product-info h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.image-product::v-deep img {
    width: 105px !important;
    border-radius: 7px;
    border: 1px solid var(--gray-1001);
}
.swipeout-list::v-deep .swipeout .swipeout-right {
    right: 0;
    transform: translateX(101%);
}
.add-to-wishlist .action-container, .remove-from-cart .action-container {
    width: 115px;
    background-color: var(--light-gray-100);
}
:is(.add-to-wishlist, .remove-from-cart) svg {
    fill: var(--gray-1001);
}
.swipeout-list::v-deep .swipeout-left .swipeout-action, .swipeout-list::v-deep .swipeout-right .swipeout-action {
    transition: var(--transition-1)
}
.swipeout-list::v-deep :is(.action-active-left, .action-active-right) .action-container {
    background-color: var(--main-red) !important;
    transition: var(--transition-1);
}
.swipeout-list::v-deep :is(.action-active-left, .action-active-right) .action-container svg {
    fill: white;
}
.swipeout-list::v-deep .pause-movement-left.swipeout-content {
    transform: translateX(112px) !important;
}
.swipeout-list::v-deep .pause-movement-right.swipeout-content {
    transform: translateX(-104px) !important;
}
.swipeout-list::v-deep .pause-movement-neutral.swipeout-content {
    transform: translateX(0px) !important;
}

/* .rebound-right {
    animation: regular-rebound-right 0.3s;
    animation-delay: 0.1s;
}
@keyframes regular-rebound-right {
    0% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(8px);
    }
    100%{
        transform: translateX(0);
    }
}
.rebound-left {
    animation: rebound-left 0.3s;
    animation-delay: 0.1s;
}
@keyframes rebound-left {
    0% {
        transform: translateX(0);
    }
    33% {
        transform: translateX(-8px);
    }
    100%{
        transform: translateX(0);
    }
} */

/* @media (max-width: 767px) { */
    /* .swipeout-list {
        width: 97%;
    }
    .swipeout-list.swiper-container {
        padding-inline: 10px;
    }
    .swipeout-list.swiper-container::v-deep .swipeout-list-item {
        padding-inline: 12px;
    } */
/* } */
</style>
