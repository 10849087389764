<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 99.952">
        <path id="Order" d="M-2874.176,1927.2v-18.841a3.634,3.634,0,0,0-3.634-3.634h-34.018v32.242l-12.345-8.344-12.351,8.344v-32.242h-34.013a3.639,3.639,0,0,0-3.639,3.634v87.916a8.4,8.4,0,0,0,8.4,8.4h83.159a8.4,8.4,0,0,0,8.4-8.4Z" transform="translate(2974.176 -1904.721)"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconSubtotal',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
