<template>
    <main-confirm @confirm="$emit('confirm')" :isQuestion="false" @cancel="$emit('closeModal')" confirm-title="account.undo_changes" 
    secondTitle="account.this_action_cannot_be_reversed" description="account.are_you_sure_you_want_to_undo_the_changes">
        <icon-warning :size="60"/>
    </main-confirm>
</template>

<script>
    import MainConfirm from '../../../../../common/components/modals/MainConfirm.vue'
    import IconWarning from '../../../../../common/svg/iconWarning.vue'
    export default {
        name: 'ConfirmCancel',
        components:{ MainConfirm, IconWarning }
    }

</script>