<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 75.731">
        <g id="Delete_cart" data-name="Delete cart" transform="translate(299.69 -414.57)">
            <g id="Capa_2" data-name="Capa 2">
            <ellipse id="Ellipse_420" data-name="Ellipse 420" cx="50" cy="4.099" rx="50" ry="4.099" transform="translate(-299.69 482.102)" fill="#f0f4f9"/>
            </g>
            <g id="Capa_4" data-name="Capa 4">
            <g id="Group_6902" data-name="Group 6902">
                <g id="Group_6901" data-name="Group 6901">
                <path id="Path_2764" data-name="Path 2764" d="M-258.035,477.988a6.31,6.31,0,0,1-6.311-6.31,6.31,6.31,0,0,1,6.311-6.311,6.31,6.31,0,0,1,6.31,6.311h0A6.316,6.316,0,0,1-258.035,477.988Z" fill="#1a2f44"/>
                <path id="Path_2765" data-name="Path 2765" d="M-235.95,477.988a6.311,6.311,0,0,1-6.311-6.31,6.311,6.311,0,0,1,6.311-6.311,6.31,6.31,0,0,1,6.31,6.311h0A6.316,6.316,0,0,1-235.95,477.988Z" fill="#1a2f44"/>
                </g>
            </g>
            <path id="Path_2766" data-name="Path 2766" d="M-233,427.5h-40.763l-1.977-6.891a7.879,7.879,0,0,0-7.576-5.73h-7.8a1.616,1.616,0,0,0-1.607,1.264,1.58,1.58,0,0,0,1.237,1.86,1.54,1.54,0,0,0,.311.031h7.856a4.726,4.726,0,0,1,4.544,3.433l10.031,35.011a7.879,7.879,0,0,0,7.576,5.73h29.671a7.877,7.877,0,0,0,7.481-5.427l3.519-12.023a.785.785,0,0,0-.481-.951,17.721,17.721,0,0,1-11.247-15.553A.785.785,0,0,0-233,427.5Z" fill="#1a2f44"/>
            <g id="Group_6903" data-name="Group 6903">
                <path id="Path_2767" data-name="Path 2767" d="M-214.571,414.57a12.776,12.776,0,0,0-12.767,12.786,12.775,12.775,0,0,0,12.785,12.767,12.777,12.777,0,0,0,12.768-12.785h0A12.777,12.777,0,0,0-214.571,414.57Zm5.049,19a.815.815,0,0,1-.2-.017,1.35,1.35,0,0,1-.705-.377l-4.13-4.13-4.147,4.131a1.254,1.254,0,0,1-.688.377.82.82,0,0,1-.394,0,1.205,1.205,0,0,1-1-1.377,1.29,1.29,0,0,1,.376-.705l4.15-4.132-4.131-4.145a1.27,1.27,0,0,1-.393-.689,1.209,1.209,0,0,1,.327-1.049,1.228,1.228,0,0,1,1.705,0l4.2,4.18,4.179-4.18a1.228,1.228,0,0,1,1.7,0,1.21,1.21,0,0,1,.328,1.049,1.256,1.256,0,0,1-.377.689l-4.148,4.145,4.148,4.13a1.446,1.446,0,0,1,.393.706c0,.065.016.131.016.2A1.206,1.206,0,0,1-209.522,433.567Z" fill="#bc2c35"/>
            </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconDeleteCart',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>
