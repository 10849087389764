<template>
    <card class="d-flex align-items-center justify-content-center p-0 p-lg-4">
        <div v-if="viewPort('md')">
            <div class="d-flex flex-column justify-content-center w-100">
                <span class="d-flex justify-content-center title-18 weight-700 mb-0">
                    <icon-subtotal :size="18" class="me-2"/>
                    {{ $t("cart.my_order") }}
                </span>
                <span class="d-flex justify-content-center subtitle-15 mb-0 color-dark-gray-200 text-capitalize">{{ branchSelected.description | textFormat}}</span>      
                <hr class="mb-3 w-100">
            </div>
            <h6 class="mb-0 d-flex justify-content-between mb-0">
                <span class="subtitle-16 me-2 color-dark-gray-200">{{ $t("cart.subtotal") }}</span>
                <span class="title-16 weight-700">{{cart.total_amount | moneyFormat}}</span>
            </h6>
            <div class="d-flex align-items-center justify-content-center mt-2 mb-3">
                <div class="d-flex flex-column mt-2" style="width: 160px">
                    <main-button icon :title="bottonLabel" :id="'buy'"  @click="handlerBuy"
                    :disabled="products.length == 0 || loadingCart || bottonLabel == 'cart.verifying'"
                    :class="{'btn-refresh-quotation':isChanged}">
                        <half-circle-spinner :animation-duration="1000" :size="15" color="#fff" v-if="bottonLabel == 'cart.verifying'" class="me-1"/>
                        <icon-refresh color="#fff" v-else-if="isChanged" :size="15" class="me-1"/>
                        <icon-buy :color="'#fff'" :size="14" class="me-1" v-else/>
                    </main-button>
                    <second-button icon class="mt-3" :id="'mundo-movil-cart'" :title="loading ? $t('cart.loading') :$t('cart.my_carts')" @click="$emit('openCarts')">
                        <icon-cart :size="17" :color="'var(--main-navy)'" class="me-2"/>
                    </second-button> 
                </div>
            </div>
            <hr class="mb-2 mt-1 w-100">
            <div class="d-flex justify-content-center subtitle-14">
                <a href="#" @click.prevent="addToWishList" v-if="isLogged && products.length !== 0" 
                class="action-btn d-flex aling-items-center color-dark-gray-200 me-4">
                    <icon-favourite :size="14" color="var(--dark-gray-200)" class="me-2" style="transition: var(--transition-1) !important;"/>
                    {{ $t("cart.save") }}
                </a>
                <a @click.prevent="$emit('print')" href="#" class="action-btn d-flex aling-items-center color-dark-gray-200">
                    <icon-print :size="14" color="var(--dark-gray-200)" class="me-2" style="transition: var(--transition-1) !important;"/>
                    {{ $t("cart.print") }}
                </a>  
            </div>
        </div>
        <div v-else>
            <h6 class="title-16 my-2 d-flex justify-content-between">
                <span class="weight-500 color-dark-gray-200">{{ $t("cart.subtotal") }}</span>
                <span>{{cart.total_amount | moneyFormat}}</span>
            </h6>
            <hr>
            <div class="d-flex justify-content-between">
                <second-button icon :id="'mundo-movil-cart'" :title="loading ? $t('cart.loading') :$t('cart.my_carts')" @click="$emit('openCarts')" style="width: 140px; border-radius: 20px;">
                    <icon-cart :size="17" :color="'var(--main-navy)'" class="me-2"/>
                </second-button>
                <main-button icon :title="bottonLabel" :id="'buy'"  @click="handlerBuy"
                    :disabled="products.length == 0 || loadingCart || bottonLabel == 'cart.verifying'"
                    :class="{'add-width':mobile}" style="border-radius: 20px;">
                    <half-circle-spinner :animation-duration="1000" :size="15" color="#fff" v-if="bottonLabel == 'cart.verifying'" class="me-1"/>
                    <icon-buy :color="'#fff'" :size="14" class="me-1" style="margin-top: -4px;" v-else/>
                </main-button>
            </div>
        </div>
        <div v-if="loadingQuotation" class="position-absolute w-100 h-100 quotation-loading d-flex justify-content-center align-items-center">
            <half-circle-spinner :animation-duration="1000" :size="40" color="var(--main-red)"/>
        </div>
    </card>
</template>

<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'
import { mapGetters } from "vuex";
import Card from '../../../common/components/cards/Card.vue';
import IconCart from "../../../common/svg/iconCart.vue";
import IconBuy from '../../../common/svg/iconBuy.vue';
import IconFavourite from '../../../common/svg/iconFavourite.vue';
import IconPrint from '../assets/svg/iconPrint.vue';
import IconSubtotal from '../assets/svg/iconSubtotal.vue';
// import IconInvoice from '../../account/assets/svg/iconInvoice.vue';
import { HalfCircleSpinner } from 'epic-spinners'
import IconRefresh from '../../../common/svg/iconRefresh.vue';
// import IconArrow from '../../../common/svg/iconArrow.vue';

export default {
    name: "Subtotal",
    components: {
        MainButton,
        SecondButton,
        Card,
        IconCart,
        IconBuy,
        IconFavourite,
        IconPrint,
        IconSubtotal,
        // IconInvoice,
        HalfCircleSpinner,
        IconRefresh,
        // IconArrow
    },
    data(){
        return {
            loading: false,
            showCarts: false,
            loadingQuotation: false
        }
    },
    props: {
        bottonLabel: {
            type: String
        },
    },
    computed:{
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            amount: "cart/amount",
            products: "cart/getProducts",
            isLogged: "auth/isLogged",
            cart: "cart/cart",
            employeeActiveCode: 'auth/employeeActiveCode',
            selectedQuotation: "account/selectedQuotation",
            changedQuotes: "account/changedQuotes",
            loadingCart: "cart/loadingCart",
            mobile: "common/mobile",
            userData: "auth/userData",
        }),
        isChanged(){
            let index = this.changedQuotes.findIndex(item=> item.quotation == this.selectedQuotation)
            return index != -1
        },
    },
    methods:{
        addToWishList(){
            this.$store.commit("wishlist/SET_STATE_MODAL", true)
            this.$store.commit("wishlist/SELECT_PRODUCT", this.products)
        },
        async handlerBuy(){
            if((this.userData?.customer?.active || (this.employeeActiveCode)) && this.isChanged){
                this.$emit('createQuotation')
            } else{
                this.$emit('buy')
            }
        },
    }
}
</script>
<style scoped>
    .quotation-loading{
        right: 0;
        bottom: 0;
        border-radius: 20px;
        background-color: #ffffff6e !important;
        backdrop-filter: blur(1px);
        cursor: progress; 
    }
    .btn-refresh-quotation.main-button::v-deep{
        background-color: var(--blue-600);
    }
    .btn-refresh-quotation.main-button::v-deep:hover{
        background-color: #689CEE;
    }
    .btn-refresh-quotation.main-button::v-deep.pressed{
        background-color: var(--blue-700) !important;
    }
    #btn-go-to-quotation > span{
        transition: all .15s ease-in-out;
    }
    #btn-go-to-quotation:hover > span{
        color: var(--main-red) !important;
        margin-right: 18px;
    }
    #btn-go-to-quotation > svg {
        position: absolute;
        top: 5px;
        right: 0px;
        transition: all .15s ease-in-out, fill .05s ease-in-out;
        opacity: 0;
    }
    #btn-go-to-quotation:hover > svg {
        opacity: 1;
    }
    .add-width{
        width: 140px;
    }
</style>