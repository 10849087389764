<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path  d="M125,0.5c-51.5,0-93.4,42.2-93.4,94c0,73.7,84.6,149.8,88.2,153c1.5,1.3,3.3,2,5.2,2s3.7-0.7,5.2-2   
      c3.6-3.2,88.2-79.3,88.2-153C218.4,42.7,176.5,0.5,125,0.5L125,0.5z M125,145.8c-28.6,0-51.9-23.3-51.9-51.9S96.4,42,125,42   
      s51.9,23.3,51.9,51.9S153.6,145.8,125,145.8z">
      </path>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconPin',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'var(--light-main-navy)'
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
