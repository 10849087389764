

<template>
  <icon-base :width="`${size}%`" :fill="color" viewBox="0 0 762.878 482.054" class="icon-big-empty-cart">
    <g id="Empty_cart_icon_" data-name="Empty cart icon " transform="translate(-383.214 5675.348)">
        <g id="Group_7604" data-name="Group 7604">
          <g id="Group_6019" data-name="Group 6019">
            <g id="Group_6018" data-name="Group 6018">
              <g id="Group_6015" data-name="Group 6015">
                <g id="Group_5935" data-name="Group 5935">
                  <path id="Path_2395" data-name="Path 2395" d="M964.944-5308.408s-19.591-15.291-14.631-27.443,30.006,15.872,30.006,15.872-16.118-35.214,3.472-38.19,25.294,39.182,25.294,39.182,1.736-36.455,13.64-32.486,7.191,29.759,7.191,29.759,6.944-25.063,15.872-22.824,2.48,25.8,2.48,25.8,13.887-20.57,23.556-14.625,3.72,24.956,3.72,24.956Z" fill="#f0f3f7"/>
                  <g id="Group_5934" data-name="Group 5934">
                    <path id="Path_2396" data-name="Path 2396" d="M1097.283-5380.394c-4.483,7.767-13.674,10.851-20.527,6.9s-8.772-13.46-4.289-21.226,13.674-10.851,20.526-6.9S1101.767-5388.162,1097.283-5380.394Z" fill="#e9eef4"/>
                    <path id="Path_2397" data-name="Path 2397" d="M1099.66-5334.913c-6.028,4.637-14.226,4.085-18.318-1.23s-2.523-13.383,3.5-18.02,14.226-4.086,18.318,1.229S1105.684-5339.556,1099.66-5334.913Z" fill="#f0f3f7"/>
                    <ellipse id="Ellipse_387" data-name="Ellipse 387" cx="13.316" cy="14.328" rx="13.316" ry="14.328" transform="translate(1037.644 -5375.92) rotate(-26.104)" fill="#e9eef4"/>
                    <path id="Path_2398" data-name="Path 2398" d="M1105.346-5347.516c-.04-.327-.087-.65-.154-.974l-12.115,3.652,5.628-11.5c-.3-.128-.608-.24-.92-.343l-5.983,12.224-12.5,3.768h0l-16.144,4.867,15.407-36.834h0l3.033-7.252,17.047-3.4c.128-.353.232-.709.337-1.063l-16.93,3.375,9-21.516c-.309-.114-.615-.227-.927-.317l-8.855,21.168-6.2-16.522c-.251.264-.5.525-.742.807l6.385,17.028-.951,2.271h0l-11.936,28.536-4.2-9.2h0l-.218-.477,4.115-16.179q-.331-.607-.709-1.172l-4.055,15.934L1052.64-5382.8a9.934,9.934,0,0,0-.966.235l5.888,12.891c-3.583-.383-11-1.156-15.1-1.427-.031.32-.047.642-.055.966,3.722.263,10.732.966,15.629,1.494l5.427,11.881h0l3.858,8.439-16.535,39.53.9.378,10.979-26.245,16.873-5.084h0l4.72-1.423,13.828,7.3c.32-.186.633-.394.943-.608l-13.438-7.094Z" fill="#ccd7e2"/>
                  </g>
                </g>
                <g id="Group_5940" data-name="Group 5940">
                  <g id="Group_5938" data-name="Group 5938">
                    <g id="Group_5937" data-name="Group 5937">
                      <g id="Group_5936" data-name="Group 5936">
                        <path id="Path_2399" data-name="Path 2399" d="M491.661-5309.561c-5.647,1.478-31.184,5.717-30.775-14.627.209-10.386-2.821-10.015-8.908-14.083s-5.941-6.677-5.407-12.489-5.261-3-9.122-10.644c-4.619-9.146.942-15.97,9.915-17.612s8.713,8.037,12.279,10.922,8.5-3.319,15.351,5.092c4.474,5.493-2.472,18.021,4.108,16.853,10.614-1.883,17.563,6.286,19.585,12.687C501.253-5325.35,496.99-5310.959,491.661-5309.561Z" fill="#f0f3f7"/>
                      </g>
                    </g>
                  </g>
                  <g id="Group_5939" data-name="Group 5939">
                    <path id="Path_2400" data-name="Path 2400" d="M491.661-5309.561c-.284-.393-14.673-22.914-40.015-52.012" fill="none" stroke="#fff" stroke-width="1"/>
                    <path id="Path_2401" data-name="Path 2401" d="M465.774-5362.654l-1.113,16.573-13.325,1.1" fill="none" stroke="#fff" stroke-width="0.8"/>
                    <path id="Path_2465" data-name="Path 2465" d="M472.62-5353.815l-1.113,16.573-13.325,1.1" fill="none" stroke="#fff" stroke-width="0.8"/>
                  </g>
                  <path id="Path_2465-2" data-name="Path 2465-2" d="M478.564-5346.41l-1.112,16.573-13.326,1.1" fill="none" stroke="#fff" stroke-width="0.8"/>
                  <path id="Path_2465-3" data-name="Path 2465-3" d="M484.768-5337.574,483.659-5321l-13.325,1.1" fill="none" stroke="#fff" stroke-width="0.8"/>
                </g>
                <g id="Group_5945" data-name="Group 5945">
                  <g id="Group_5943" data-name="Group 5943">
                    <g id="Group_5942" data-name="Group 5942">
                      <g id="Group_5941" data-name="Group 5941">
                        <path id="Path_2402" data-name="Path 2402" d="M443.8-5437.229c-11.846-9.645-14.679-39.457,2.2-48.5,20.867-11.19,42.715,6.129,45.463,23.938,2.12,13.737-7.488,23.331,17.516,33.4s21.145,22.576,16,46.493,11.761,15.149,24.893,35.335c10.406,16-4.218,32.314-10.737,38.4l-51.143-.249a37.591,37.591,0,0,1-4.737-3.286c-16.518-13.5-9.956-34.832-8.242-51.444,1.855-17.969-23.979-9.936-34.268-33S455.642-5427.58,443.8-5437.229Z" fill="#dfe6ed"/>
                      </g>
                    </g>
                  </g>
                  <path id="Path_2403" data-name="Path 2403" d="M529.449-5308.517s-60.845-134.628-76.1-161.894" fill="none" stroke="#f5f5f5" stroke-width="1"/>
                  <g id="Group_5944" data-name="Group 5944">
                    <line id="Line_208" data-name="Line 208" x1="33.931" y1="1.799" transform="translate(448.777 -5411.678)" fill="none" stroke="#f5f5f5" stroke-width="0.8"/>
                    <line id="Line_209" data-name="Line 209" x1="38.158" y1="4.115" transform="translate(478.81 -5339.904)" fill="none" stroke="#f5f5f5" stroke-width="0.8"/>
                  </g>
                  <line id="Line_210" data-name="Line 210" x1="19.937" y2="42.904" transform="translate(499.587 -5416.796)" fill="none" stroke="#fafbfb" stroke-width="0.8"/>
                  <path id="Path_2404" data-name="Path 2404" d="M471.186-5465.733c1.659,2.336,4.545,3.223,6.452,1.981s2.1-4.144.444-6.481-4.544-3.224-6.452-1.981S469.531-5468.071,471.186-5465.733Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                  <path id="Path_2405" data-name="Path 2405" d="M469.975-5453.1a2.335,2.335,0,0,0,2.235,2.431,2.336,2.336,0,0,0,2.431-2.235,2.335,2.335,0,0,0-2.235-2.431h-.1A2.284,2.284,0,0,0,469.975-5453.1Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                  <path id="Path_2406" data-name="Path 2406" d="M453.061-5395.417a4.537,4.537,0,0,0,4.342,4.724,4.539,4.539,0,0,0,4.724-4.342,4.537,4.537,0,0,0-4.342-4.724h-.192A4.441,4.441,0,0,0,453.061-5395.417Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                </g>
                <line id="Line_211" data-name="Line 211" x2="761.878" transform="translate(383.714 -5307.626)" fill="none" stroke="#a5b2b7" stroke-linecap="round" stroke-width="1"/>
              </g>
            </g>
          </g>
        </g>
        <rect id="Rectangle_1782" data-name="Rectangle 1782" width="761.878" height="482.054" transform="translate(383.714 -5675.348)" fill="none"/>
        <g id="Group_5946" data-name="Group 5946">
          <ellipse id="Ellipse_388" data-name="Ellipse 388" cx="156.306" cy="14.542" rx="156.306" ry="14.542" transform="translate(608.347 -5290.828)" fill="#f0f4f9"/>
        </g>
        <g class="container-object">
          <g class="object init-ellipse-390" data-value-y="1">
            <circle id="Ellipse_390" data-name="Ellipse 390" cx="4.285" cy="4.285" r="4.285" transform="translate(785.442 -5420.506)" fill="#dae5ea" opacity="0.5" style="mix-blend-mode: normal;isolation: isolate"/>
          </g>
        </g>
        <g class="container-object">
          <g class="object init-ellipse-391" data-value-y="2">
            <circle id="Ellipse_391" data-name="Ellipse 391" cx="3.75" cy="3.75" r="3.75" transform="translate(836.797 -5441.022)" fill="none" stroke="#dae5ea" stroke-width="1.5"/>
          </g>
        </g>
        <g class="container-object">
          <g class="object init-ellipse-392" data-value-y="1">
            <circle id="Ellipse_392" data-name="Ellipse 392" cx="2.651" cy="2.651" r="2.651" transform="translate(828.678 -5465.047)" fill="#dae5ea" opacity="0.5" style="mix-blend-mode: normal;isolation: isolate"/>
          </g>
        </g>
        <g class="container-object">
          <g class="object init-ellipse-393" data-value-y="1.3">
            <circle id="Ellipse_393" data-name="Ellipse 393" cx="2.651" cy="2.651" r="2.651" transform="translate(865.676 -5424.255)" fill="#dae5ea" opacity="0.5" style="mix-blend-mode: normal;isolation: isolate"/>
          </g>
        </g>
        <g class="object init-ellipse-394" data-value-y="0.88">
          <g class="container-object"> 
            <circle id="Ellipse_394" data-name="Ellipse 394" cx="5.135" cy="5.135" r="5.135" transform="translate(793.577 -5454.358)" fill="none" stroke="#dae5ea" stroke-width="1"/>
          </g>
        </g>
        <g class="object init-group-6063" data-value-y="0.5">
          <g class="container-object">
            <g id="Group_6063" data-name="Group 6063">
              <line id="Line_214" data-name="Line 214" x2="3.117" y2="8.687" transform="translate(881.803 -5454.359)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1.5"/>
              <line id="Line_215" data-name="Line 215" x1="8.687" y2="3.117" transform="translate(879.018 -5451.574)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </g>
        </g>
          <g class="object init-group-6064" data-value-y="1">
            <g class="container-object">
              <g id="Group_6064" data-name="Group 6064">
                <line id="Line_216" data-name="Line 216" x1="2.014" y2="6.878" transform="translate(894.58 -5416.221)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1.2"/>
                <line id="Line_217" data-name="Line 217" x1="6.878" y1="2.014" transform="translate(892.148 -5413.789)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1.2"/>
              </g>
            </g>
          </g>
          <g class="object init-group-6065" data-value-y="0.9">
            <g class="container-object">
              <g id="Group_6065" data-name="Group 6065">
                <line id="Line_218" data-name="Line 218" x2="2.148" y2="4.521" transform="translate(827.604 -5415.064)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_219" data-name="Line 219" x1="4.521" y2="2.148" transform="translate(826.417 -5413.877)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
              </g>
            </g>
          </g>

        <g class="object init-message-cart" data-value="-2">
          <g id="Group_7611" data-name="Group 7611" class="container-object">
            <g id="Group_5950" data-name="Group 5950">
              <g id="Group_5948" data-name="Group 5948">
                <path id="Path_2407" data-name="Path 2407" d="M769.345-5606.9H731.319a8.8,8.8,0,0,0-8.8,8.8h0v37.962l8.945-8.212h37.878a8.8,8.8,0,0,0,8.8-8.8h0V-5598.1A8.8,8.8,0,0,0,769.345-5606.9Z" fill="none" stroke="#f0f3f7" stroke-width="1.5"/>
              </g>
            </g>
            <g id="Group_6066" data-name="Group 6066">
              <g id="Group_5947" data-name="Group 5947">
                <path id="Path_2408" data-name="Path 2408" d="M747.2-5576.234a2.012,2.012,0,0,1-2.012,2.012,2.012,2.012,0,0,1-2.012-2.012,2.012,2.012,0,0,1,2.012-2.012h0A2.011,2.011,0,0,1,747.2-5576.234Z" fill="none" stroke="#dae5ea" stroke-width="1"/>
                <path id="Path_2409" data-name="Path 2409" d="M757.755-5575.973a2.012,2.012,0,0,1-2.012,2.012,2.012,2.012,0,0,1-2.012-2.012,2.012,2.012,0,0,1,2.012-2.012h0a2.012,2.012,0,0,1,2.012,2.012Z" fill="none" stroke="#dae5ea" stroke-width="1"/>
                <path id="Path_2410" data-name="Path 2410" d="M734.337-5596.6h6.155l2.226,16.546,16.406-.368" fill="none" stroke="#dae5ea" stroke-width="1"/>
                <path id="Path_2411" data-name="Path 2411" d="M741.138-5593.827H760.93l-1.806,10.357H742.263" fill="none" stroke="#dae5ea" stroke-width="1"/>
              </g>
              <g id="Group_5949" data-name="Group 5949">
                <circle id="Ellipse_389" data-name="Ellipse 389" cx="7.611" cy="7.611" r="7.611" transform="translate(753.646 -5601.342)" fill="#cf4448"/>
                <g id="_0" data-name=" 0">
                  <g id="_0-2" data-name=" 0-2" style="mix-blend-mode: normal;isolation: isolate">
                    <text id="_0-2-2" data-name="0" transform="translate(758.538 -5591.716)" fill="#fff" font-size="9" font-family="CenturyGothic-Bold, Century Gothic" font-weight="700" style="mix-blend-mode: normal;isolation: isolate"><tspan x="0" y="0">0</tspan></text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="object init-group-6008" data-value="1">
          <g id="Group_6008" data-name="Group 6008" class="container-object">
            <g id="Group_6006" data-name="Group 6006">
              <path id="Path_2457" data-name="Path 2457" d="M784.632-5517.749h-25.45a5.888,5.888,0,0,0-5.887,5.887h0v14.024l-4.456,6.926,10.443-1.039h25.351a5.889,5.889,0,0,0,5.888-5.887v-14.027A5.887,5.887,0,0,0,784.632-5517.749Z" fill="none" stroke="#dae5ea" stroke-width="1"/>
            </g>
            <g id="Group_6007" data-name="Group 6007">
              <rect id="Rectangle_1493" data-name="Rectangle 1493" width="4.29" height="8.288" transform="translate(763.556 -5511.363)" fill="#dae5ea"/>
              <path id="Path_2458" data-name="Path 2458" d="M769.113-5503.02v-8.327s7.971-1.244,10.2,0c1.616.9,1.934,3.148,2.164,7.082s-5.573,3.015-5.573,3.015-.032,5.77-1.737,6.3-1.934-1.9-2-3.935A4.53,4.53,0,0,0,769.113-5503.02Z" fill="#dae5ea"/>
            </g>
          </g>
        </g>
        <g id="Group_6014" data-name="Group 6014" class="object init-message-cart" data-value="-2">
          <g id="Group_6013" data-name="Group 6013" class="container-object">
            <g id="Group_6009" data-name="Group 6009">
              <path id="Path_2459" data-name="Path 2459" d="M634.046-5554H659.6a5.911,5.911,0,0,1,5.913,5.909h0v14.083l3.133,7.463L659.5-5528.1H634.046a5.91,5.91,0,0,1-5.912-5.908v0h0v-14.083a5.91,5.91,0,0,1,5.911-5.911h0Z" fill="none" stroke="#dfe6ed" stroke-width="1.3"/>
            </g>
            <g id="Group_6012" data-name="Group 6012">
              <g id="Group_6011" data-name="Group 6011">
                <g id="Group_6010" data-name="Group 6010">
                  <path id="Path_2460" data-name="Path 2460" d="M645.646-5550.173a8.894,8.894,0,0,0-8.894,8.894c0,.085,0,.169,0,.254a8.993,8.993,0,0,0,8.64,8.638,8.9,8.9,0,0,0,9.157-8.625,8.9,8.9,0,0,0-8.625-9.157C645.834-5550.172,645.74-5550.173,645.646-5550.173Zm0,17.017a8.124,8.124,0,0,1-8.121-8.125c0-.076,0-.152,0-.228a8.211,8.211,0,0,1,7.89-7.888,8.122,8.122,0,0,1,8.349,7.889c0,.077,0,.154,0,.231h0A8.131,8.131,0,0,1,645.646-5533.156Z" fill="#dfe6ed"/>
                  <path id="Path_2461" data-name="Path 2461" d="M643.989-5542.825a.994.994,0,0,0-.994-.994.994.994,0,0,0-.994.994.994.994,0,0,0,.994.994.994.994,0,0,0,.994-.994Z" fill="#dfe6ed"/>
                  <path id="Path_2462" data-name="Path 2462" d="M648.3-5543.819a.994.994,0,0,0-.994.994.994.994,0,0,0,.994.994.994.994,0,0,0,.994-.994A.994.994,0,0,0,648.3-5543.819Z" fill="#dfe6ed"/>
                  <path id="Path_2463" data-name="Path 2463" d="M645.646-5539.35a5.056,5.056,0,0,0-3.769,1.676.388.388,0,0,0,.062.544.389.389,0,0,0,.514-.029,4.31,4.31,0,0,1,6.088-.3q.156.141.3.3a.385.385,0,0,0,.545.023.386.386,0,0,0,.03-.538A5.056,5.056,0,0,0,645.646-5539.35Z" fill="#dfe6ed"/>
                </g>
              </g>
            </g>
          </g>
        </g>
                <g id="Guy_shopping" data-name="Guy shopping">
          <g id="Cart">
            <path id="Path_2419" data-name="Path 2419" d="M800.881-5280.94c5.486.185,8.506-7.042,4.483-10.779-6.062-5.8-14.685,2.769-9,8.9v.035A6.147,6.147,0,0,0,800.881-5280.94Z" fill="#fff" opacity="0.69" style="mix-blend-mode: normal;isolation: isolate"/>
            <path id="Path_2464" data-name="Path 2464" d="M775.992-5425.439h-3.423L795-5299.592l3.318-.586Z" fill="#dfe6ed"/>
            <g id="Group_5982" data-name="Group 5982">
              <g id="Group_5981" data-name="Group 5981">
                <g id="Group_5953" data-name="Group 5953">
                  <g id="Group_5952" data-name="Group 5952">
                    <g id="Group_5951" data-name="Group 5951">
                      <g id="Layer0_7_MEMBER_6_MEMBER_0_MEMBER_1_FILL" data-name="Layer0 7 MEMBER 6 MEMBER 0 MEMBER 1 FILL">
                        <path id="Path_2413" data-name="Path 2413" d="M743.365-5426.388l-5.02.209c.627-.2,24.916,128.754,24.916,128.754H866.8a1.42,1.42,0,0,0,1.529-1.3,1.426,1.426,0,0,0,0-.226q.059-1.53-1.529-1.588c-64.19.278-96.426-.157-99.325-1.293C763.948-5303.288,744.031-5426.388,743.365-5426.388Z" fill="#dfe6ed"/>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_5955" data-name="Group 5955">
                  <path id="Path_2414" data-name="Path 2414" d="M889.867-5349.926H754.636a.928.928,0,0,1-1.027-.816.928.928,0,0,1,.816-1.027.953.953,0,0,1,.211,0H889.867a.927.927,0,0,1,1.027.816.928.928,0,0,1-.816,1.027h0A.953.953,0,0,1,889.867-5349.926Zm4.857-25.393H750.178a.928.928,0,0,1-1.022-.822.928.928,0,0,1,.822-1.022h0a.977.977,0,0,1,.2,0H894.724a.928.928,0,0,1,1.022.822.928.928,0,0,1-.822,1.022h0a.978.978,0,0,1-.2,0Z" fill="#dfe6ed"/>
                </g>
                <path id="Path_2479" data-name="Path 2479" d="M884.587-5322.958a1.023,1.023,0,0,1-.11.013.923.923,0,0,1-.971-.87l-4.162-76.195a.91.91,0,0,1,.849-.968h.021a.921.921,0,0,1,.971.868h0l4.162,76.195A.927.927,0,0,1,884.587-5322.958Z" fill="#dfe6ed"/>
                <g id="Group_5963" data-name="Group 5963">
                  <g id="Group_5958" data-name="Group 5958">
                    <g id="Group_5957" data-name="Group 5957">
                      <g id="Group_5956" data-name="Group 5956">
                        <g id="_0_Layer0_0_FILL_FL" data-name=" 0 Layer0 0 FILL FL">
                          <path id="Path_2416" data-name="Path 2416" d="M771.157-5276.38c9.426.319,14.612-12.1,7.7-18.516-10.414-9.969-25.226,4.758-15.459,15.284v.058A10.551,10.551,0,0,0,771.157-5276.38Z" fill="#4979b2"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_5971" data-name="Group 5971">
                  <g id="Group_5966" data-name="Group 5966">
                    <g id="Group_5965" data-name="Group 5965">
                      <g id="Group_5964" data-name="Group 5964">
                        <g id="_0_Layer0_0_FILL_FL-3" data-name=" 0 Layer0 0 FILL FL-3">
                          <path id="Path_2418" data-name="Path 2418" d="M800.9-5276.38c9.426.319,14.611-12.1,7.7-18.516-10.414-9.969-25.226,4.758-15.46,15.284v.058A10.553,10.553,0,0,0,800.9-5276.38Z" fill="#4979b2"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_5980" data-name="Group 5980">
                  <path id="Path_2422" data-name="Path 2422" d="M884.722-5322.561H760.806v-2.527H883.525l14.534-74.518H744.518v-2.526H899.75a1.5,1.5,0,0,1,1.088.45,1.167,1.167,0,0,1,.314,1.029l-15.024,77.044A1.387,1.387,0,0,1,884.722-5322.561Z" fill="#dfe6ed"/>
                </g>
              </g>
              <path id="Path_2423" data-name="Path 2423" d="M776.841-5425.44H728.673l1.477-10.38h46.694a5.193,5.193,0,0,1,5.193,5.193h0A5.193,5.193,0,0,1,776.841-5425.44Z" fill="#212e3d"/>
              <path id="Path_2424" data-name="Path 2424" d="M733.457-5430.224a4.785,4.785,0,0,1-4.783,4.785,4.784,4.784,0,0,1-4.785-4.783,4.784,4.784,0,0,1,4.783-4.785h0A4.784,4.784,0,0,1,733.457-5430.224Z" fill="#5a8cdb"/>
            </g>
            <path id="Path_2480" data-name="Path 2480" d="M806.267-5323.406a.921.921,0,0,1-.963-.764L792.367-5399.3a.922.922,0,0,1,.686-1.109.923.923,0,0,1,1.109.686c0,.018.008.036.011.055a.486.486,0,0,0,.009.055l12.938,75.129a.92.92,0,0,1-.746,1.066h-.006a.859.859,0,0,1-.1.007Z" fill="#dfe6ed"/>
            <path id="Path_2481" data-name="Path 2481" d="M776.427-5323.406a.921.921,0,0,1-.963-.764L762.527-5399.3a.922.922,0,0,1,.686-1.109.923.923,0,0,1,1.109.686c0,.018.008.036.011.055a.5.5,0,0,0,.009.055l12.938,75.129a.919.919,0,0,1-.744,1.066h-.007a.861.861,0,0,1-.1.007Z" fill="#dfe6ed"/>
            <path id="Path_2482" data-name="Path 2482" d="M824.391-5323.408a.928.928,0,0,1-1-.779l-11.463-75.007a.923.923,0,0,1,.807-1.026.923.923,0,0,1,1.017.748l11.464,75a.924.924,0,0,1-.771,1.052h0Z" fill="#dfe6ed"/>
            <path id="Path_2483" data-name="Path 2483" d="M840.989-5323.983h0a.922.922,0,0,1-1.051-.771h0l-11.518-74.889a.92.92,0,0,1,.768-1.05h0a.92.92,0,0,1,1.05.768v0h0l11.518,74.889a.922.922,0,0,1-.766,1.048Z" fill="#dfe6ed"/>
            <path id="Path_2484" data-name="Path 2484" d="M855.8-5323.777h0a.923.923,0,0,1-1.017-.816h0l-8.322-75.312a.922.922,0,0,1,.811-1.016h0a.921.921,0,0,1,1.017.814h0l8.322,75.308a.922.922,0,0,1-.81,1.014Z" fill="#dfe6ed"/>
            <path id="Path_2485" data-name="Path 2485" d="M870.735-5323.814h0a.923.923,0,0,1-1.007-.828h0l-7.355-75.412a.922.922,0,0,1,.825-1.006h0a.921.921,0,0,1,1.005.827h0l7.355,75.413a.922.922,0,0,1-.823,1Z" fill="#dfe6ed"/>
            <g id="Group_5979" data-name="Group 5979">
              <g id="Group_5974" data-name="Group 5974">
                <g id="Group_5973" data-name="Group 5973">
                  <g id="Group_5972" data-name="Group 5972">
                    <g id="_0_Layer0_0_FILL_FL-5" data-name=" 0 Layer0 0 FILL FL-5">
                      <path id="Path_2420" data-name="Path 2420" d="M855.016-5276.38c9.426.319,14.611-12.1,7.7-18.516-10.414-9.969-25.226,4.758-15.46,15.284v.058A10.553,10.553,0,0,0,855.016-5276.38Z" fill="#4979b2"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="Group_6062" data-name="Group 6062" opacity="0.7">
              <path id="Path_2417" data-name="Path 2417" d="M771.143-5280.94c5.486.185,8.506-7.042,4.482-10.779-6.062-5.8-14.685,2.769-9,8.9v.035A6.144,6.144,0,0,0,771.143-5280.94Z" fill="#fff"/>
              <path id="Path_2417-2" data-name="Path 2417-2" d="M800.878-5280.94c5.487.185,8.506-7.042,4.483-10.779-6.063-5.8-14.685,2.769-9,8.9v.035A6.142,6.142,0,0,0,800.878-5280.94Z" fill="#fff"/>
              <path id="Path_2421" data-name="Path 2421" d="M855-5280.94c5.485.185,8.506-7.042,4.482-10.779-6.062-5.8-14.685,2.769-9,8.9v.035A6.144,6.144,0,0,0,855-5280.94Z" fill="#fff"/>
            </g>
          </g>
          <g id="Guy">
            <path id="Path_2412" data-name="Path 2412" d="M754.525-5438.437s-20.8-8.66-26.854-13.612c-1.085-10.2,2.039-24.532,2.529-31.244,1.478-20.224-24.126-23.442-24,3.665.1,21.861,4.017,30.936,8.917,40.719,10.41,4.359,35.427,4.261,35.427,4.261Z" fill="#ecb38d"/>
            <g id="Group_6004" data-name="Group 6004">
              <g id="Group_5990" data-name="Group 5990">
                <path id="Path_2425" data-name="Path 2425" d="M701.339-5287.763l-.839-9.9,13.136-1.453-.3,10.039-4.032,4.566Z" fill="#f9c39b"/>
                <g id="Group_5989" data-name="Group 5989">
                  <g id="Group_5984" data-name="Group 5984">
                    <path id="Path_2426" data-name="Path 2426" d="M730.93-5277.665a1.547,1.547,0,0,1-.337.854l-30.773-.05c-.081-.379-.149-.752-.206-1.113-.974-6.341,1.474-10.3,1.474-10.3,3.345,1.808,7.477,2.027,12.242-.794a16.252,16.252,0,0,0,2.7,1.7,15.313,15.313,0,0,0,1.866.669h0c.295.085.585.163.875.23h0a21.092,21.092,0,0,0,2.151.371h0c2.823.352,5.286.249,7.181,1.391C731.026-5282.945,731.24-5279.333,730.93-5277.665Z" fill="#0b4870"/>
                    <g id="Group_6057" data-name="Group 6057">
                      <g id="Group_7606" data-name="Group 7606">
                        <g id="Group_7605" data-name="Group 7605" clip-path="url(#clip-path)">
                          <g id="Group_6056" data-name="Group 6056">
                            <g id="Group_5983" data-name="Group 5983">
                              <path id="Path_2427" data-name="Path 2427" d="M738.826-5277.8a2.961,2.961,0,0,1-.612,1.465l-46.257-.253c-.122-.571-.224-1.134-.31-1.683Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_5986" data-name="Group 5986">
                    <g id="Group_5985" data-name="Group 5985">
                      <path id="Path_2429" data-name="Path 2429" d="M717.893-5286.706a.448.448,0,0,1-.062.017,4.407,4.407,0,0,0-2.294,1.827.708.708,0,0,1-.967.257.706.706,0,0,1-.257-.966,5.268,5.268,0,0,1,1.715-1.8A15.255,15.255,0,0,0,717.893-5286.706Z" fill="#fff"/>
                    </g>
                  </g>
                  <g id="Group_5988" data-name="Group 5988">
                    <g id="Group_5987" data-name="Group 5987">
                      <path id="Path_2430" data-name="Path 2430" d="M720.927-5286.1h-.019a4.041,4.041,0,0,0-2.124,1.763.707.707,0,0,1-.967.257.706.706,0,0,1-.257-.966,5.294,5.294,0,0,1,1.214-1.435h0A20.093,20.093,0,0,0,720.927-5286.1Z" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_5996" data-name="Group 5996">
                <path id="Path_2431" data-name="Path 2431" d="M655.509-5287.408l-.781-9.9,13.145-1.376-.361,10.037-4.058,4.544Z" fill="#f9c39b"/>
                <g id="Group_5995" data-name="Group 5995">
                  <g id="Group_5992" data-name="Group 5992">
                    <path id="Path_2432" data-name="Path 2432" d="M685.041-5277.138a1.537,1.537,0,0,1-.341.853l-30.771-.229c-.078-.379-.144-.753-.2-1.119-.937-6.347,1.534-10.294,1.534-10.294,3.333,1.826,7.464,2.069,12.25-.724a16.23,16.23,0,0,0,2.689,1.717,15.02,15.02,0,0,0,1.863.679h0c.295.087.584.167.874.234h0c.737.174,1.452.294,2.148.387h0c2.822.371,5.287.278,7.173,1.436C685.169-5282.417,685.36-5278.8,685.041-5277.138Z" fill="#0b4870"/>
                    <g id="Group_6059" data-name="Group 6059">
                      <g id="Group_7608" data-name="Group 7608">
                        <g id="Group_7607" data-name="Group 7607" clip-path="url(#clip-path-2)">
                          <g id="Group_6058" data-name="Group 6058">
                            <g id="Group_5991" data-name="Group 5991">
                              <path id="Path_2433" data-name="Path 2433" d="M691-5277.2a2.722,2.722,0,0,1-.569,1.341l-42.435-.478c-.109-.526-.2-1.041-.279-1.547Z" fill="#fff"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Group_5993" data-name="Group 5993">
                    <path id="Path_2435" data-name="Path 2435" d="M672.063-5286.251a.475.475,0,0,1-.064.017,4.406,4.406,0,0,0-2.3,1.814.709.709,0,0,1-.981.2.709.709,0,0,1-.239-.917,5.265,5.265,0,0,1,1.725-1.8A15.126,15.126,0,0,0,672.063-5286.251Z" fill="#fff"/>
                  </g>
                  <g id="Group_5994" data-name="Group 5994">
                    <path id="Path_2436" data-name="Path 2436" d="M675.088-5285.631h-.019a4.039,4.039,0,0,0-2.133,1.753.707.707,0,0,1-.972.238.706.706,0,0,1-.263-.929l.015-.025a5.25,5.25,0,0,1,1.222-1.429h0A21.821,21.821,0,0,0,675.088-5285.631Z" fill="#fff"/>
                  </g>
                </g>
              </g>
              <path id="Path_2437" data-name="Path 2437" d="M703.877-5416.617s-10.288,28.293-14.245,35.415l1.88-1.78s.792,12.86.2,16.619C691.706-5366.364,704.468-5394.359,703.877-5416.617Z" fill="#101342"/>
              <g id="Group_5998" data-name="Group 5998">
                <path id="Path_2438" data-name="Path 2438" d="M666.716-5426.682a394.718,394.718,0,0,0-3.71,39.437c-.636,19.07-6.785,30.512-8.694,38.776s0,52.226,0,52.226h14.42s2.968-33.473,9.967-47.777a176.583,176.583,0,0,0,10.387-26.22s-2.12,26.38,2.12,38.457,8.271,34.746,8.271,34.746H714.11s-.636-68.434,3.181-83.214,4.241-41.477,4.241-41.477Z" fill="#212e3d"/>
                <g id="Group_6061" data-name="Group 6061">
                  <g id="Group_7610" data-name="Group 7610">
                    <g id="Group_7609" data-name="Group 7609" clip-path="url(#clip-path-3)">
                      <g id="Group_6060" data-name="Group 6060">
                        <g id="Group_5997" data-name="Group 5997">
                          <path id="Path_2439" data-name="Path 2439" d="M689.089-5370.241s18.026-26.009,18.026-35.968c0,0,1.556,36.021-18.026,51.488A29.1,29.1,0,0,1,689.089-5370.241Z" fill="#0e141a"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_6003" data-name="Group 6003">
                <path id="Path_2441" data-name="Path 2441" d="M719.781-5504.329c-10.32.174.543,34.93,2.214,42.642a50,50,0,0,0,9.8-1.5S733.47-5502.229,719.781-5504.329Z" fill="#cf4448"/>
                <path id="Path_2442" data-name="Path 2442" d="M670.018-5439.933c-2.87-33.016,3.29-65.5,24.5-66.622l24.112,1.688c11.985,4.226,8.123,28.522,8.123,28.522l-5.082,56.725s-48.706,8.633-55.068-4.415C664.589-5428.16,670.379-5435.779,670.018-5439.933Z" fill="#cf4448"/>
                <path id="Path_2443" data-name="Path 2443" d="M716.063-5499.951c-.5,2.324-4.73,3.38-9.452,2.36s-8.131-3.734-7.627-6.057,4.73-3.381,9.45-2.36S716.566-5502.277,716.063-5499.951Z" fill="#f9c39b"/>
                <path id="Path_2444" data-name="Path 2444" d="M732.3-5440.758s-22.292-8.9-32.4-12.9c-1.085-10.2,2.3-24.979,2.782-31.691,1.476-20.223-24.127-23.442-24,3.665.105,21.86,4.017,30.936,8.917,40.72,10.41,4.359,42.037,6.182,42.037,6.182Z" fill="#f9c39b"/>
                <path id="Path_2445" data-name="Path 2445" d="M695.253-5504.331c-10.219-1.442-22.78,4.857-20.887,42.695,4.315.61,28.235-1.469,28.235-1.469S708.451-5500.11,695.253-5504.331Z" fill="#cf4448"/>
                <g id="Group_5999" data-name="Group 5999">
                  <path id="Path_2446" data-name="Path 2446" d="M712.421-5516.186c-5.286,2.7-10.786-3.287-10.786-3.287-3.593.692-4.266-2.89-4.266-2.89a4.646,4.646,0,0,1-5.014-4.152c-.377-2.811,2.213-3.631,1.416-6.792-.641-2.536-4.6-7.866,3.01-11.209a6.631,6.631,0,0,1,2.789-7.419c5.24-3.449,7.933-.529,10.675-.841,3.061-.347,8.142-5.161,11.333,1.044,0,0,4.042-1.391,6.962.846a7.234,7.234,0,0,1,2.319,7.63s3.617-.093,5.482,2.676a6.053,6.053,0,0,1-.129,6.822,6.3,6.3,0,0,1-2.191,1.822c-1.234.7-.016,4.245-.92,5.352a28.372,28.372,0,0,1-5.008,4.659Z" fill="#212e3d"/>
                  <path id="Path_2447" data-name="Path 2447" d="M715.113-5508.3h0a14.652,14.652,0,0,1-13.43-15.78h0v0l.793-9.838a14.652,14.652,0,0,1,15.782-13.429h0a14.653,14.653,0,0,1,13.429,15.783h0l-.793,9.839a14.655,14.655,0,0,1-15.783,13.429Z" fill="#f9c39b"/>
                  <path id="Path_2448" data-name="Path 2448" d="M703.967-5529.016s2.66-11.95,8.968-9.045c4.412,2.031,19,7.384,19.278,3.888.877-10.883-6.028-16.879-18.11-17.109-10.387-.835-17.722,6.377-19.6,17.942s6.987,15.917,6.987,15.917Z" fill="#212e3d"/>
                  <path id="Path_2449" data-name="Path 2449" d="M714.75-5508.666l-.615,7.617-13.839-1.577,1.645-20.393Z" fill="#f9c39b"/>
                  <path id="Path_2450" data-name="Path 2450" d="M705.37-5526.689c.393,2.758-.864,5.219-2.807,5.5s-3.837-1.736-4.23-4.494.864-5.219,2.806-5.5S704.977-5529.447,705.37-5526.689Z" fill="#f9c39b"/>
                </g>
                <g id="Group_6000" data-name="Group 6000">
                  <path id="Path_2451" data-name="Path 2451" d="M726.286-5462.082l-.821.118c.02-.194,1.374-24.655,1.374-24.655A240.277,240.277,0,0,1,726.286-5462.082Z" fill="#af3139"/>
                </g>
                <g id="Group_6001" data-name="Group 6001">
                  <path id="Path_2452" data-name="Path 2452" d="M673.829-5461.144s.255-20.96,2.466-28.155c0,0-1.9,16.332-1.391,27.173a28.33,28.33,0,0,0,5.411.5l.2,1.16C680.193-5460.447,673.829-5461.144,673.829-5461.144Z" fill="#af3139"/>
                </g>
                <g id="Group_6002" data-name="Group 6002">
                  <path id="Path_2453" data-name="Path 2453" d="M699.795-5461.745l.072-1.131a8.134,8.134,0,0,0,2.252-.5c.352-1.838,3.314-22.479,3.314-22.479a132.086,132.086,0,0,1-2.333,23.134A8.791,8.791,0,0,1,699.795-5461.745Z" fill="#af3139"/>
                </g>
                <path id="Path_2454" data-name="Path 2454" d="M746.192-5436.244s5.824-.029,7.049,1.915c1.56,2.473.5,4.962,1.531,5.777.64.484,1.382.025,1.952-.791.156.715.548,1.23,1.008,1.271a1.3,1.3,0,0,0,1.5-1.059c.006-.037.011-.074.015-.112.294,1.072.81,1.074,1.365.707.91-.6,2.429-7.883-1.892-10.11a15.1,15.1,0,0,0-8.632-1.372Z" fill="#ecb38d"/>
                <path id="Path_2455" data-name="Path 2455" d="M729.644-5434.787a15.146,15.146,0,0,1,5.379.136c1.561,2.473.349,5.287,1.374,6.1.64.484,1.382.025,1.947-.791.157.715.549,1.23,1.009,1.271a1.3,1.3,0,0,0,1.5-1.061c.006-.036.011-.073.015-.11.3,1.072.811,1.074,1.366.707.91-.6,2.658-8.857-1.663-11.079-4.2-2.158-10.839-1.531-10.839-1.531Z" fill="#f9c39b"/>
              </g>
              <path id="Path_2456" data-name="Path 2456" d="M713.609-5515.358s1.4-1.965,4.736-1.411,2.749,3.254,2.749,3.254" fill="none" stroke="#dc8063" stroke-linecap="round" stroke-width="1"/>
            </g>
          </g>
        </g>
      </g>
  </icon-base>
</template>

<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
  components: { iconBase },
  name: 'IconBigEmptyCart',
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'var(--main-navy)'
    }
  }
}
</script>

<style scoped>
    .container-object, .container-object-1{
      animation: up-down 2s infinite alternate;
    }
    .container-object:nth-child(2){
      animation-delay: 0.5s;
    }
    .container-object:nth-child(3){
      animation-delay: 1s;
    }
    .container-object:nth-child(4){
      animation-delay: 0.5s;
    }
    .container-object:nth-child(5){
      animation-delay: 1s;
    }
    .container-object:nth-child(6){
      animation-delay: 1s;
    }
    @keyframes  up-down {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }
    .init-ellipse-390{
      animation: init-ellipse-390 1s;
    }
    @keyframes init-ellipse-390 {
        from{
            opacity:0;
            transform: translate(50px, 100px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    .init-ellipse-394{
      animation: init-ellipse-390 1s; 
    }
    .init-group-6065{
      animation: init-group-6065 1s; 
    }
    @keyframes init-group-6065 {
        from{
            opacity:0;
            transform: translate(10px, 100px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    .init-group-6013{
      animation: init-group-6065 1s; 
    }
    @keyframes init-group-6065 {
        from{
            opacity:0;
            transform: translate(50px, 30px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    .init-message-cart{
      animation: init-message-cart 1s; 
    }
    @keyframes init-message-cart {
      from{
          opacity:0;
          transform: translate(-30px, 100px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .init-group-6008{
      animation: init-group-6008 1s; 
    }
    @keyframes init-group-6008 {
      from{
          opacity:0;
          transform: translate(-80px, 0px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .init-ellipse-392{
      animation: init-ellipse-392 1s; 
    }
    @keyframes init-ellipse-392 {
      from{
          opacity:0;
          transform: translate(0px, 100px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .init-ellipse-391{
      animation: init-ellipse-391 1s; 
    }
    @keyframes init-ellipse-391 {
      from{
          opacity:0;
          transform: translate(-10px, 100px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .init-ellipse-393, .init-group-6063{
      animation: init-ellipse-393 1s; 
    }
    @keyframes init-ellipse-393 {
      from{
          opacity:0;
          transform: translate(-40px, 100px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .init-group-6064{
      animation: init-group-6064 1s; 
    }
    @keyframes init-group-6064 {
      from{
          opacity:0;
          transform: translate(-60px, 100px);
      }to{
          opacity:1;
          transform: translate(0px, 0px);
      }
    }
    .icon-big-empty-cart{
      margin-top: -50px;
      margin-bottom: -70px;
    }
    @media (max-width: 475px) {
      .icon-big-empty-cart{
        margin-top: -20px;
        margin-bottom: -40px;
      }
    }
</style>
